export class EditCases {
  //Takes kababCase or sentanceCase and converts to camelCase:
  static camelCase(string) {
    try {
      return EditCases.kababCase(string)
        .split("-")
        .map((word, index) => {
          if (index === 0) return word;
          return word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join("");
    } catch (err) {
      console.log(err);
      return string;
    }
  }

  //Takes camelCase or sentanceCase and converts to kababCase:
  static kababCase(string) {
    let words = string.split(" ");
    string = string?.trim();
    if (words.length > 1) {
      string = words
        .map((w) =>
          w
            .split("")
            .map((l, i) => (i > 0 ? l.toLowerCase() : l))
            .join(""),
        )
        .join(" ");
    }

    return string
      .split("")
      .map((letter, index) => {
        if (/[A-Z]/.test(letter)) {
          return ` ${letter.toLowerCase()}`;
        }
        return letter;
      })
      .join("")
      .trim()
      .replace(/_/g, "-")
      .replace(/\s+/g, "-");
  }

  //Takes camelCase or kababCase and converts to sentanceCase:
  static sentanceCase(string) {
    try {
      const interim = EditCases.kababCase(string).replace(/-/g, " ");
      return interim.slice(0, 1).toUpperCase() + interim.slice(1);
    } catch (err) {
      console.log(err);
      return string;
    }
  }

  static titleCase(string) {
    try {
      return EditCases.kababCase(string)
        .split("-")
        .map((word) => {
          return word.slice(0, 1).toUpperCase() + word.slice(1);
        })
        .join(" ");
    } catch (err) {
      console.log(err);
      return string;
    }
  }

  static workspaceCase(string) {
    string = string.toUpperCase().replaceAll(" ", "_");
    string = string.toUpperCase().replaceAll("-", "_");
    string = string.replace(/[^A-Z_0-9]/, "");
    return string;
  }
}

export class EditNumbers {
  static isNumber(number) {
    return !isNaN(parseFloat(number)) && !isNaN(number - 0);
  }
  static floatString(string) {
    return Number.parseFloat(string).toFixed(2);
  }
  static numberToString(number) {
    return number.toSring();
  }
}

const isObject = (object) => object != null && typeof object === "object";

export const objectMatch = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !objectMatch(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

export const createDocument = (inputs, original = {}) => {
  let result = {};

  let datasets = Array.from(inputs).map((input) => input.dataset);

  datasets.forEach(({ key, value = null, ...rest }) => {
    let oldValue = original[key]?.toString();

    let isUnique;
    if (value) {
      isUnique = value.toString().toLowerCase() !== oldValue;
      if (isUnique || value == null) {
        result[key] = value;
      }
    } else {
      let values = Object.values(rest);
      values = values.map((v) => v.toString().toLowerCase());
      isUnique = !values.includes(oldValue);

      if (isUnique) {
        result[key] = { ...rest };
      }
    }
  });
  return result;
};

export const isUniqueValue = (existing, newValue) => {
  let type = Array.isArray(existing) ? "array" : typeof existing;

  let special = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const hasSpecialCharacters = (string) => special.test(string);

  let isUnique = false;

  const toCompare = existing.map((string) => string.toLowerCase());
  const checkValue = newValue.toLowerCase();

  if (hasSpecialCharacters(checkValue)) {
    throw new Error("No special characters!");
  }

  switch (type) {
    case "array":
      if (toCompare.includes(checkValue)) {
        throw new Error("Option already exists, enter a unique value.");
      } else {
        isUnique = true;
      }
      break;
    case "string":
      if (toCompare === newValue) {
        throw new Error("Option already exists, enter a unique value.");
      } else {
        isUnique = true;
      }
      break;
  }

  return isUnique;
};

export const removePlural = (text) => {
  text = text.trim();
  const lastChar = text.charAt(text.length - 1);
  text = lastChar === "s" ? text.slice(0, -1) : text;

  return EditCases.sentanceCase(text);
};

//Flashes a colour change on update:
export const highlightChange = (el) => {
  el.classList.add("highlight-change");
  const toggleChange = () => {
    el.classList.remove("highlight-change");
  };
  setTimeout(toggleChange, 300);
};

export const getTextWidth = (input, value, adjustment = 20) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (!value) {
    value = input.value;
  }

  const style = window.getComputedStyle(input, null);
  const fontSize = parseFloat(style.getPropertyValue("font-size"));
  const letterSpacing = parseFloat(style.getPropertyValue("letter-spacing"));

  context.font = "bold " + fontSize + "px" + " Arial";
  context.letterSpacing = letterSpacing + "px";
  const width = context.measureText(value).width;
  const finalWidth = Math.ceil(width + adjustment);

  return finalWidth;
};
