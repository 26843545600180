import AuthUser from "../realm/auth.mjs";
import { EditCases } from "../modules/format.mjs";
import { pageIcons } from "./local.mjs";
import { CalloutController } from "./forms.mjs";
const { camelCase, sentanceCase, kababCase, titleCase } = EditCases;

/* ALL UI FUNCTIONS */
//Short expression of document.querys...:
export const dqs = (selector) => document.querySelector(selector);
export const dqa = (selector) => document.querySelectorAll(selector);

export class navigationBuilder {
  constructor(header) {
    this.header = header;
  }

  insertNavigation() {
    const sideBar = `<div id="side-bar">
      <div id="side-bar-wrapper">
      <button aria-label="close-navigation" name="close-navigation" class="close-button">${pageIcons.cnxIcon}</button>
        <nav id="navigation"></nav>
      </div>
    </div>`;

    const navigationWrapper = `<div class="navigation-grid">
      <div id="navigation-burger" class="header-item"><div>${pageIcons.burgerIcon}</div></div>
      <div id="update-callout"></div>
      <div id="user-account" class="header-item"><button name="user-account" aria-label="view-account" id="account-button">${pageIcons.accountIcon}</button></div>
    </div>`;

    const accountModal = `<div id="account-modal" class="callout-wrapper callout-modal top">
    <div class="callout border-callout">
      <div id="account-wrapper">
        <div id="account-details" class="acc-item">
          <div id="user-name"></div>
          <div id="workspace-name"></div>
        </div>
        <div id="setttings" class="acc-item">
          <a href="./settings.html" class="nav-link">Settings</a>
        </div>
        <div id="logout">
          <a>
            <span>logout</span>
            <span class="modal-icon">${pageIcons.logoutIcon}</span>
          </a>
        </div>
      </div>
      <b class="border-notch notch"></b>
      <b class="notch"></b>
      <b class="border-fill"></b>
    </div>
    </div>`;

    this.header.innerHTML = sideBar + navigationWrapper + accountModal;

    this.handleSideBar();

    //User logout/
    const logoutButton = this.header.querySelector("#logout");
    logoutButton.addEventListener("click", (e) => {
      e.preventDefault();
      AuthUser.logout()
        .then(() => {
          window.location = "./index.html";
        })
        .catch((err) => console.log(err.message));
    });
  }

  setNavigation(userType, fields) {
    const navigation = dqs("#navigation");
    const userAccount = dqs("#user-account");
    const currentPage = window.location.pathname;

    let params = "";
    let searchParams = new URLSearchParams(window.location.search);

    if (searchParams.size) {
      params = searchParams.toString();
      params = "?" + params;
    }

    const logIn = `<a href="./login.html${params}"><button aria-label="log-in" id="log-in-button" class= "nav-btn">${pageIcons.accountIcon} Login</button></a>`;
    const register = `<a href="./register.html${params}"><button aria-label="register" id="register-button" class= "nav-btn">${pageIcons.accountIcon} Register</button></a>`;
    const account = `<button aria-label="view-account" id="account-button" class= "nav-btn">${pageIcons.accountIcon}</button>`;

    switch (userType) {
      case "user":
        userAccount.innerHTML = account;

        const accountButton = dqs("#account-button");
        const accountModal = dqs("#account-modal");
        const dynamicModal = dqs("#dynamic-modal");

        accountButton.addEventListener("click", (e) => {
          accountModal.classList.add("active");
          dynamicModal.classList.add("active");
        });

        break;
      default:
        if (currentPage.includes("login")) {
          userAccount.innerHTML = register;
        } else {
          userAccount.innerHTML = logIn;
        }

        break;
    }

    navigation.innerHTML = "";

    fields.forEach((field) => {
      let link = field.link;
      let collection = field.collection;

      let params = "";
      let name = "";

      if (collection) {
        name = sentanceCase(collection);
        params = `?collection=${collection}`;
      } else {
        name = sentanceCase(link);
      }

      navigation.insertAdjacentHTML(
        "beforeend",
        `<a class="nav-link"  href="./${link}.html${params}">${name}</a>`,
      );
    });
  }

  setAccountModal(wsUser) {
    const accItems = dqa(".acc-item");

    if (wsUser) {
      //Hides fields which users without a ws account cannot access:
      accItems.forEach((item) => item.classList.add("active"));
    } else {
      //Hides fields which users without a ws account cannot access:
      accItems.forEach((item) => item.classList.remove("active"));
    }
  }

  handleSideBar() {
    const closeButton = this.header.querySelector(".close-button");

    const sideBar = dqs("#side-bar");
    const burger = dqs("#navigation-burger");

    burger.addEventListener("click", (e) => {
      sideBar.classList.toggle("active");
    });

    closeButton.addEventListener("click", (e) => {
      sideBar.classList.remove("active");
    });
  }
}

export class uiBuilder {
  static createAccordion({ key, wrapper, fields } = {}) {
    const title = sentanceCase(key);
    wrapper.innerHTML = `<h1>${title}</h1><div id="${key}-accordion" class="accordion-wrapper"></div>`;

    const accordionWrapper = dqs(`#${key}-accordion`);

    fields.forEach((field) => {
      accordionWrapper.insertAdjacentHTML(
        "beforeend",
        `<div id="${field}-section" class="accordion-item-wrapper active">      
          <button id="${field}-button" aria-label="${field}" value="${field}" class="accordion-button">
            <a class="accord-text">${sentanceCase(field)}</a>
            <a class="accord-icon">${pageIcons.plusIcon}</a>
          </button>      
          <div class="accordion-panel">
          <div class="panel-items"></div>
          </div>
        </div>`,
      );

      const button = accordionWrapper.querySelector(`#${field}-button`);
      button.addEventListener("click", (e) => {
        const { currentTarget } = e;
        const wrapper = currentTarget.closest(".accordion-item-wrapper");
        wrapper.classList.toggle("active");
      });
    });
  }

  static createTabMenu({
    key,
    wrapper,
    orientation,
    fields,
    options,
    callback = null,
  } = {}) {
    const title = sentanceCase(key);

    wrapper.innerHTML = `<div id="${key}-menu" class="tab-menu ${orientation}">
      <div class="tab-menu-title"><h1>${title}</h1></div>
      <div class="tab-buttons-wrapper"></div>
      <div class="tabs-wrapper"></div>`;

    const tabButtons = wrapper.querySelector(".tab-buttons-wrapper");
    const tabsWrapper = wrapper.querySelector(".tabs-wrapper");

    fields.forEach((key, index) => {
      let active = "";
      const id = kababCase(key);

      const title = options[key].title.toUpperCase();
      const message = options[key].message;

      if (index === 0) active = "active";

      let name = sentanceCase(key);

      tabButtons.insertAdjacentHTML(
        "beforeend",
        `<button id="${id}-button" aria-label="${id}" value="${id}" class="tab-button ${active}">${name}</button>`,
      );

      tabsWrapper.insertAdjacentHTML(
        "beforeend",
        `<div id="${id}-tab" class="tab-wrapper ${active}">
            <div class="tab-title">
              <h2 class="tab-title">${title}</h2>
              <p class="tab-message">${message}</p>
            </div>
            <div id="${id}-tab-body" class="tab-body">
            <div/>
        </div>`,
      );

      const tabButton = tabButtons.querySelector(`#${id}-button`);

      tabButton.addEventListener("click", async (e) => {
        const activeTabGroup = wrapper.querySelectorAll(
          `.tab-button.active, .tab-wrapper.active`,
        );

        activeTabGroup?.forEach((element) => {
          element?.classList.remove("active");

          const isTab = Array.from(element.classList).includes("tab-wrapper");

          const children = element?.childrens || [];
          if (isTab && children.length) {
            element.innerHTML = "";
          }
        });

        const selectedTab = tabsWrapper.querySelector(`#${id}-tab`);

        [tabButton, selectedTab]?.forEach((element) => {
          element?.classList.add("active");
        });

        if (callback) {
          await callback(id);
        }
      });
    });
  }
}

export class AddScrollFlow {
  constructor({ scrollAxis, flowWrapper } = {}) {
    this.scrollAxis = scrollAxis;
    this.flowWrapper = flowWrapper;
  }

  setPosition() {
    const flowChildren = this.flowWrapper.querySelectorAll(".options-wrapper");

    let rowHeight = EditCssVariants.getCssVariant("--row-height");
    rowHeight = Number(rowHeight.replace("px", ""));

    flowChildren.forEach((flowChild) => {
      const flowParent = flowChild?.closest(".input-wrapper");
      const options = flowChild.querySelectorAll(".option");

      //Calculated as display none has no height:
      const vertualHeight = options.length * rowHeight;

      const flowChildDims = flowChild.getBoundingClientRect();

      const flowParentDims = flowParent.getBoundingClientRect();
      const flowWrapperDims = this.flowWrapper.getBoundingClientRect();

      const flowWrapperBottom = flowWrapperDims.bottom;
      const tolerance = vertualHeight * 1.5 + flowChildDims.top;

      const parentIn = flowParentDims.bottom - 5 < flowWrapperBottom;
      const childOut = tolerance > flowWrapperBottom;

      const active = flowChild.closest(".matrix-item.active");

      const enableFlow = () => {
        flowChild.classList.add("flow-child");
        flowChild.style.top = flowParentDims.bottom + 1 + "px";
        if (!flowChild?.style?.width) {
          flowChild.style.width = flowParentDims.width - 1 + "px";
        }
      };

      if (parentIn && childOut && active) {
        enableFlow();
      } else {
        flowChild.classList.remove("flow-child");
        flowChild.style = "";
        flowParent.blur();
      }
    });
  }

  static detectScroll(el) {
    let xs = el.scrollWidth > el.clientWidth;
    let ys = el.scrollHeight > el.clientHeight;

    if (ys) {
      el.classList.add("ys");
    } else {
      el.classList.remove("ys");
    }

    if (xs) {
      el.classList.add("xs");
    } else {
      el.classList.remove("xs");
    }

    return { xs: xs, ys: ys };
  }

  createFlow() {
    const handleScrollFlow = (e) => {
      const { currentTarget } = e;
      this.setPosition();
    };

    this.constructor.detectScroll(this.flowWrapper);

    this.flowWrapper.classList.add("scroll-flow");
    const handleScroll = (e) => handleScrollFlow(e);

    this.flowWrapper.addEventListener("scroll", handleScroll, false);
    document.addEventListener("scroll", handleScroll, false);
  }
}

export class EditCssVariants {
  static getCssVariant = (key) =>
    getComputedStyle(document.documentElement).getPropertyValue(key);

  static setCssVariant = (key, value) =>
    document.documentElement.style.setProperty(key, value);

  static getStyle = (element, style) =>
    window.getComputedStyle(element, null).getPropertyValue(style);
}

export const debounce = (callback, wait = 300) => {
  let timeoutId = null;
  return (...args) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback.apply(null, args);
    }, wait);
  };
};

export const throttle = (callback, interval = 300) => {
  // A flag variable to track whether the function is running or not
  let isRunning = false;
  // Return a function that takes arguments
  return (...args) => {
    // If the function is not running
    if (!isRunning) {
      // Set the flag to true
      isRunning = true;
      // Apply the function with arguments
      callback.apply(this, args);
      // Set a timer that will reset the flag after the interval
      setTimeout(() => {
        // Set the flag to false
        isRunning = false;
      }, interval);
    }
  };
};

export const setScrollPosition = debounce(({ el, top = 0, left = 0 } = {}) => {
  //Enables page scroll:
  document.body.classList.remove("disable-scroll");

  //Repositions the list scroll to the top:
  el.scrollTo({
    top: top,
    left: left,
    behavior: "instant",
  });
}, 250);

//Currently not used but may be useful
/*
const copyToClipboard = (text) =>
  navigator.clipboard &&
  navigator.clipboard.writeText &&
  navigator.clipboard.writeText(text);

//Detects darkmode as a OS preference:
const isDarkMode = () =>
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;
*/
