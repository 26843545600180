"use strict";
import * as Realm from "realm-web";

export const {
  BSON: { ObjectId },
  BSON: { Double },
  BSON: { Int32 },
  BSON: { EJSON },
} = Realm;

const id = "costpriceapp-nfhcgse";

const config = { id };

export const app = new Realm.App(config);

export default class AuthUser {
  static async register(email, password) {
    try {
      return await app.emailPasswordAuth.registerUser({
        email,
        password,
      });
    } catch (err) {
      throw err;
    }
  }

  static async confirmUser(token, tokenId) {
    try {
      //Confirm client
      const options = { token, tokenId };
      await app.emailPasswordAuth.confirmUser(options);
      return "sucess";
    } catch (err) {
      throw ("There was an error confirming your email:", err);
    }
  }

  static async resendConfirmation(email) {
    try {
      const result = await app.emailPasswordAuth.resendConfirmationEmail({
        email,
      });

      return result;
    } catch (err) {
      throw ("There was an error confirming your email:", err);
    }
  }

  static async createNewWorkspace(newWorkspace) {
    try {
      const user = app.currentUser;
      const workspace = await user.functions.authCreateWorkspace(newWorkspace);
      await user.refreshCustomData();
      return workspace;
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async joinWorkspace(workspaceDoc) {
    try {
      await app.currentUser.functions.authJoinWorkspace(workspaceDoc);
      await app.currentUser.refreshCustomData();
    } catch (err) {
      throw err;
    }
  }

  static async login(email, password) {
    try {
      if (!email || typeof email !== "string") {
        throw "Bad email format, expected string.";
      }
      if (!password || typeof password !== "string") {
        throw "Bad password format, expected string.";
      }

      const credentials = Realm.Credentials.emailPassword(email, password);

      // Authenticate the user
      const user = await app.logIn(credentials);

      return user;
    } catch (err) {
      throw err;
    }
  }

  static async authGoogle(response) {
    let user;
    const idToken = response.credential;
    const credentials = Realm.Credentials.google({ idToken });

    try {
      user = await app.logIn(credentials);
    } catch (err) {
      throw err;
    }

    if (user) {
      console.log("User:", user);
      return user;
    } else {
      console.log("Login unsuccessful.");
    }
  }

  static async requestPasswordReset(email) {
    try {
      if (!email || typeof email !== "string") {
        throw "Bad email format, expected string.";
      }

      //Reset password the user:
      await app.emailPasswordAuth.sendResetPasswordEmail({
        email,
      });
    } catch (err) {
      throw err;
    }
  }

  static async resetPassword(token, tokenId, password) {
    try {
      await app.emailPasswordAuth.resetPassword({
        password: password,
        token,
        tokenId,
      });
    } catch (err) {
      throw err;
    }
  }

  static async getWorkspace() {
    const user = app.currentUser;
    await app.currentUser.refreshCustomData();

    try {
      const result = await user?.functions?.authGetWorkspace();
      console.log("getWorkspace/result", result);
      return result;
    } catch (err) {
      console.log("Error getting workspace data:", err);
      throw err;
    }
  }

  static async logout() {
    try {
      await app.currentUser.logOut();
    } catch (err) {
      throw err;
    }
  }

  static async logoutAllUsers() {
    const allUsers = app.allUsers;
    const logOutAllUsers = Object.keys(allUsers);
    for (const userId of logOutAllUsers) {
      await app.allUsers[userId].logOut();
    }
  }
}

export class AuthServices {
  static async installShopify() {
    const cloudFunc = app.currentUser.functions;
  }

  async veryifyShopifyAccount(params) {
    try {
      const functions = app.currentUser.functions;
      const result = await functions.veryifyShopifyAccount(params);
      return result;
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async uninstallShopify() {
    try {
      const functions = app.currentUser.functions;
      const result = await functions.shopifyRequestUninstall();
      return result;
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async installQuickbooks(mode = "sandbox") {
    const cloudFunc = app.currentUser.functions;

    try {
      return await cloudFunc.quickbooksOAuth(mode);
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async uninstallQuickbooks() {
    try {
      const functions = app.currentUser.functions;
      const result = await functions.quickbooksUninstall();
      return result;
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async installXero() {
    const cloudFunc = app.currentUser.functions;

    try {
      return await cloudFunc.xeroOAuth();
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }

  static async uninstallXero() {
    try {
      const functions = app.currentUser.functions;
      const result = await functions.xeroUninstall();
      return result;
    } catch (err) {
      const error = EJSON.parse(err.error);
      throw error;
    }
  }
}
