"use strict";
import "./styles/index.scss";
import { FormBuilder } from "./modules/forms.mjs";
import { dqa, dqs, navigationBuilder } from "./modules/ui.mjs";
import { tableSettings } from "./modules/local.mjs";
import WorkspaceController from "./realm/workspace.mjs";
import dmFavicon from "./img/cpp-favicon-dark.svg";
import lmFavicon from "./img/cpp-favicon-light.svg";

const findFocus = () => {
  document.addEventListener(
    "focusin",
    function () {
      console.log("focused: ", document.activeElement);
    },
    true,
  );
};

//findFocus()

const { authFields, navigationFields, conditions } = tableSettings;

//Loads the navigation:
const header = dqs("HEADER");
const loadNavigation = new navigationBuilder(header);

//Gets the dynamic modal (wrapper for all modals):
const dynamicModal = dqs("#dynamic-modal");
FormBuilder.handleModalClose(dynamicModal);

const setupWorkspaceUI = (user) => {
  //Selects the acc menu items:
  const userName = dqs("#user-name");
  const workspaceName = dqs("#workspace-name");

  const userWorkspace = user.workspace;
  //Sets up the account menu:
  userName.textContent = user.name.firstName + " " + user.name.lastName;
  workspaceName.textContent = userWorkspace;

  //Displays the workspace name where required:
  if (workspaceName) {
    workspaceName.textContent = userWorkspace;
  }
};

const authorizeUser = async () => {
  let user = {},
    role,
    hasWorkspace,
    invitedUser;

  try {
    user = await WorkspaceController.getUser();
    console.log("User logged in:", user);
    role = user?.role;
    hasWorkspace = user?.wsid;
    invitedUser = user?.nwsu;
  } catch (err) {
    console.log(err.message);
  }

  // SETS UP THE UI BASED ON ACCOUNT DETAILS
  //Selects all Adim only resources:
  const adminItems = dqa(".admin-items");

  let currentModal;

  const currentLocation = window.location.pathname;
  const userNaviagation = navigationFields.user;

  loadNavigation.insertNavigation();

  if (Object.keys(user).length) {
    //Loads the user navigation:
    loadNavigation.setNavigation("user", navigationFields.user);

    //Detects admins and display/hides admin only items:
    switch (role) {
      case "wsAdmin":
      case "admin":
        adminItems.forEach((item) => item.classList.add("active"));
        break;
      case "wsEditor":
        adminItems.forEach((item) => item.classList.remove("active"));
        break;
      default:
    }

    if (hasWorkspace) {
      //Handles users invited to join a workspace:
      if (invitedUser) {
        //Loads the join workspace form:
        const authParams = {
          wrapper: dynamicModal,
          type: "modal",
          key: "join-workspace",
          fields: authFields.joinWorkspace,
          conditions: conditions,
        };

        const joinForm = new FormBuilder(authParams);
        joinForm.insertForm();
        joinForm.insertFormOptions();

        //Assigns the Join Ws Modal to the Acc button as,
        //the user is logged in & has been asigned ws account:
        currentModal = dqs("#join-workspace-modal");
        dynamicModal.classList.add("active");
        currentModal.classList.add("active");
      } else {
        //sets up the Worspace UI:
        setupWorkspaceUI(user);
        //Only users with a wsid can access account info:
        loadNavigation.setAccountModal(hasWorkspace);
      }
    } else {
      const userPages = userNaviagation.map(
        (path) => "/" + path.link + ".html",
      );

      if (userPages.includes(currentLocation)) {
        window.location = "./create-workspace.html";
      }
    }
  } else {
    //Loads the visitor navigation:
    loadNavigation.setNavigation("visitor", navigationFields.visitor);

    //When user is logged out the login modal is added to the UI:
    adminItems.forEach((item) => item.classList.add("hide-item"));
    const userPages = userNaviagation.map((path) => "/" + path.link + ".html");

    if (userPages.includes(currentLocation)) {
      window.location = "./login.html";
    }
  }
};

try {
  await authorizeUser();
} catch (err) {
  console.log(`Error authorizing user: ${err.message}`);
}

// ALL CLICK OUTSIDE EVENTS
//Listens for click outside events:
const sideBar = dqs("#side-bar");

const burger = dqs("#navigation-burger");
document.addEventListener("click", (e) => {
  const { currentTarget, target } = e;
  if (!sideBar?.contains(target) && !burger?.contains(target)) {
    sideBar?.classList?.remove("active");
  }

  const dropdowns = currentTarget.querySelectorAll(".custom-dropdown.active");

  if (dropdowns?.length) {
    dropdowns.forEach((dropdown) => {
      if (!dropdown?.contains(target)) {
        dropdown.classList.remove("active");
        dropdown.blur();
      }
    });
  }
});

// select the favicon
const faviconEl = document.querySelector('link[rel="icon"]');

// listener
const themeChange = async (e) => {
  if (e.matches) {
    faviconEl.setAttribute("href", dmFavicon);
  } else {
    faviconEl.setAttribute("href", lmFavicon);
  }
};

const loadTheme = (e) => {
  const { currentTarget } = e;
  const mediaQuery = currentTarget.matchMedia("(prefers-color-scheme: dark)");

  if (mediaQuery.matches) {
    faviconEl.setAttribute("href", dmFavicon);
  } else {
    faviconEl.setAttribute("href", lmFavicon);
  }

  console.log("theme changed");
};

// watch for changes
const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
mediaQuery.addEventListener("change", themeChange);
window.addEventListener("DOMContentLoaded", loadTheme);
