"use strict";
import { dqs, dqa, debounce } from "../modules/ui.mjs";
import AuthUser from "../realm/auth.mjs";
import WorkspaceController, { WorkspaceErrors } from "../realm/workspace.mjs";
import { EditCssVariants, AddScrollFlow } from "../modules/ui.mjs";
import { EditCases, EditNumbers } from "../modules/format.mjs";
import { getTextWidth, removePlural } from "../modules/format.mjs";
import TableBuilder from "./tables.mjs";
import { pageIcons } from "../modules/local.mjs";
import { BSON } from "realm-web";
const { ObjectId, EJSON } = BSON;

const { camelCase, kababCase } = EditCases;
const { sentanceCase, workspaceCase, titleCase } = EditCases;
const { resetList } = TableBuilder;
const { login } = AuthUser;
const { getCssVariant } = EditCssVariants;
const { getDb, insertItem, deleteDoc } = WorkspaceController;
const {
  updateWorkSpaceSettings,
  updateTypeSettings,
  updateUserSettings,
  updateSizeSettings,
  updateCurrencySettings,
} = WorkspaceController;

const simulateMouseEvent = (node, eventType) => {
  const event = new MouseEvent(eventType, {
    view: window,
    bubbles: false,
    cancelable: true,
  });
  node.dispatchEvent(event);
};

export class ValidationController {
  constructor({ form, inputs, validate = "all", conditions = {} } = {}) {
    this.form = form; //Form or row.
    this.inputs = inputs;
    this.validate = validate; //Any or all valid input allows submit.
    this.conditions = conditions;
    this.message = "";
  }

  handleValidation({ position = "indent" } = {}) {
    const rows = this.form.querySelectorAll(".matrix-item");
    const max = rows.length;

    this.inputs.forEach((input) => {
      input.addEventListener("input", (e) => {
        e.preventDefault();
        const { currentTarget } = e;

        input = currentTarget;

        const { data, type } = e;
        const id = input.id || input.name;
        const value = input.value.trim();
        const classList = Array.from(input.classList);
        const textInput = !classList.includes("virtual-input");

        let valid = true;
        let validPassword;

        if (textInput) input.dataset.value = value;

        const inputWrapper = input.closest(".input-wrapper");
        const subText = inputWrapper.querySelector(".sub-text");

        //Adjusts sub-text position:
        if (subText) {
          const offset = getTextWidth(input, input.value, 10);
          subText.style.left = offset + "px";
        }

        //Exception for workspace name:
        if (id === "workspace") input.value = workspaceCase(value);

        if (!classList.includes("virtual-input")) {
          for (const type in this.conditions) {
            const condition = this.conditions[type];
            const attribute = condition.find((attr) => attr[0] === id);

            if (attribute) {
              switch (type) {
                case "number":
                  let number = Number(value);
                  let valNum = EditNumbers.isNumber(number);
                  if (!valNum) {
                    this.message = `<a>Must be a <b>number!</b></a>`;
                    valid = valNum;
                  }

                  break;
                case "pattern":
                  let type, regex, message;
                  type = attribute[0];
                  regex = attribute[1];
                  message = attribute[2];

                  const validateRegex = (regex, message) => {
                    let valPattern =
                      value === "" ? true : this.validateString(regex, value);
                    if (!valPattern) {
                      this.message = message;
                    }
                    return valPattern;
                  };

                  const checkValidation = (isValid) => isValid === true;

                  switch (type) {
                    case "new-password":
                      validPassword = regex.map(([regex, key] = []) => ({
                        [key]: validateRegex(regex, message),
                      }));

                      //Merges the above array into an object:
                      validPassword = Object.assign({}, ...validPassword);
                      //Checks all the values are valid:

                      valid =
                        Object.values(validPassword).every(checkValidation);
                      break;
                    case "email":
                      if (!value.includes("@")) {
                        message = "";
                      }
                      let validEmail = validateRegex(regex, message);
                      if (!validEmail) valid = validEmail;
                      break;
                    default:
                      let validPattern = validateRegex(regex, message);
                      if (!validPattern) valid = validPattern;
                      break;
                  }

                  break;
                case "minlength":
                  let min = attribute[1];
                  let valMin = value.length >= min;
                  if (!valMin) {
                    this.message = `<a>No less than <b>${attribute[1]}</b> characters!</a>`;
                    valid = valMin;
                  }

                  break;
                case "maxlength":
                  let max = attribute[1];
                  let valMax = value.length <= max;

                  if (!valMax) {
                    this.message = `<a><b>${attribute[1]}</b> characters or less!</a>`;
                    valid = valMax;
                  }
                  break;
                case "match":
                  let toMatch = this.form.querySelector(
                    `#${attribute[1]}`,
                  ).value;
                  valid = value === toMatch;
                  this.message = `<a>Passwords must <b>match</b>!</a>`;

                  break;
                case "step":
                  break;
              }
            }
          }
        }

        const row = input.closest(".matrix-item");
        if (row) {
          const row_id = row.dataset._id;
          let index = [...rows].findIndex((r) => r.dataset._id === row_id);
          index = index + 1;

          if (index === max) {
            position = "above";
          } else {
            position = "indent";
          }
        }

        const event = { type: type, currentTarget: input };

        const options = {
          position: position,
          element: input,
          message: {
            type: "error",
            value: this.message,
            valid: valid,
          },
        };

        if (this.message) {
          const calloutMesage = new CalloutController(options);
          calloutMesage.insertCalloutMessage();
          calloutMesage.handleMessage(event);
          if (validPassword) calloutMesage.setPasswordCallout(validPassword);
        }

        this.setValidation(input, valid);
        this.cancelForm();
      });
    });
  }

  validateString(regex, string) {
    regex = new RegExp(regex, "gm");
    return regex.test(string);
  }

  //Alters the input's class based on the outcome of each validation:
  setValidation(input, valid) {
    const wrapper = input.closest("[class*=-wrapper]");
    const highlight = wrapper.querySelector(".highlight-input");

    if (valid) {
      input.classList.remove("error");
      input.classList.add("is-valid");
      if (highlight) highlight.style.backgroundColor = `var(--highlight)`;
    } else {
      input.classList.add("error");
      input.classList.remove("is-valid");
      if (highlight) highlight.style.backgroundColor = `var(--error)`;
    }
    const original = input.defaultValue.toLowerCase();
    const value = input.value.trim().toLowerCase();

    const noValue = input.value === "";
    const noChange = original === value;
    const isText = input.id !== "colors";
    const isOptional = input.classList.contains("optional");

    if (original && noChange && isText) {
      input.classList.remove("is-valid");
      input.classList.remove("error");
      if (highlight) highlight.style.backgroundColor = `var(--highlight)`;
    }

    if (!noValue && isOptional) {
      input.classList.add("is-valid");
      input.classList.add("capture");
    }

    if (noValue) {
      const isBridge = wrapper.classList.contains("bridge");

      if (isOptional) {
        input.classList.add("is-valid");
        input.classList.remove("capture");
      }

      if (!isBridge && !isOptional) {
        input.classList.remove("is-valid");
      }

      if (highlight) highlight.style.backgroundColor = `var(--highlight)`;
    }

    this.validateSubmit();
  }

  //Validates if the form is correct and ready to submit:
  validateSubmit() {
    let form, inputs, submit, matrixInputWrapper;
    let validateAll, validateAny;

    inputs = this.form.querySelectorAll(".capture:not(.system.virtual-input)");
    submit = this.form.querySelector(`[type="submit"]`);

    if (!submit) {
      //EXCEPTION FOR STAGED FORMS:
      const stageForm = this.form.closest("FORM");
      form = stageForm;
      submit = stageForm.querySelector(`[type="submit"]`);
    } else {
      form = this.form;
    }

    const selectors = ".add-option-wrapper.active, .matrix-item.active";
    matrixInputWrapper = this.form.querySelector(selectors);
    if (matrixInputWrapper) {
      inputs = matrixInputWrapper.querySelectorAll(".capture");
      submit = matrixInputWrapper.querySelector(`[type="submit"]`);

      validateAll = [...inputs].filter((i) => i.closest(".add-option-wrapper"));
      validateAny = [...inputs].filter((i) => i.closest(".matrix-item"));
    }

    const checkValidation = (isValid) => isValid === true;

    //Pushes true or false to the validations array:
    const getValidation = (inputs) =>
      [...inputs].map(({ classList } = {}) => classList.contains("is-valid"));

    const checkErrors = (inputs) =>
      [...inputs].map(({ classList } = {}) => classList.contains("error"));

    let validateInputsBy = this.validate;

    let validated = false;
    switch (validateInputsBy) {
      case "all":
        validated = getValidation(inputs).every(checkValidation);
        break;
      case "any":
        let errors = checkErrors(inputs).some(checkValidation);

        if (errors) {
          validated = !errors;
        } else {
          validated = getValidation(inputs).some(checkValidation);
        }
        break;
      case "dual":
        if (validateAll.length) {
          validated = getValidation(validateAll).every(checkValidation);
        } else {
          validated = getValidation(validateAny).some(checkValidation);
        }
        break;
      case "bridge":
        //Bridges are considered one input for validation purposes:
        const reduceObjects = (acc, { name, classList, value } = {}) => {
          acc[name] = acc[name] || [];
          if (!value.length && validateAll) {
            acc[name].push(false);
            classList.remove("is-valid");
          } else {
            acc[name].push(classList.contains("is-valid"));
          }
          return acc;
        };

        if (validateAll.length) {
          let bridgeSets = [...validateAll].reduce(reduceObjects, {}) || {};

          let validate = [];
          for (const key in bridgeSets) {
            validate.push(bridgeSets[key].some(checkValidation));
          }

          validated = validate.every(checkValidation);
        } else {
          validated = getValidation(validateAny).some(checkValidation);
        }

        break;
    }

    if (submit) {
      submit.disabled = !validated;
    }

    const hasError = Array.from(form.classList).includes("error");
    if (validated && hasError) {
      form.classList.remove("error");
      const errorMessage = form.querySelector("A.error");
      if (errorMessage) errorMessage.remove();
    }

    const skuInputs = dqa(".new-sku-input");
    const optionalInputs = dqa(".form-input.optional");

    if (skuInputs.length) {
      if (validated) {
        [...skuInputs, ...optionalInputs].forEach((input) =>
          input.classList.add("is-valid"),
        );
      } else {
        skuInputs.forEach((input) => input.classList.remove("is-valid"));
      }
    }
  }

  cancelForm() {
    const cancel = this.form.querySelector("INPUT[type=reset]");
    const sumbit = this.form.querySelector("[type=submit]");
    if (cancel) {
      cancel.addEventListener("click", (e) => {
        e.preventDefault();
        this.resetForm();
        sumbit.disabled = true;
      });
    }
  }

  resetForm(values = {}) {
    const inputs = this.form.querySelectorAll(
      "INPUT.is-valid, .new-sku-input.is-valid",
    );
    const optionalInputs = this.form.querySelectorAll("INPUT.optional.capture");

    optionalInputs.forEach((input) => input.classList.remove("capture"));

    inputs.forEach((input) => {
      const inputWrapper = input.closest(".input-wrapper");
      const fieldset = input.closest("FIELDSET");
      const selected = inputWrapper.querySelector(".dropdown-selected");

      let set = fieldset?.dataset?.set;
      let key = input.dataset.key;

      const hasValue = Object.keys(values).length;

      if (hasValue) {
        let value;

        //In case of fieldset (nested) values:
        if (set) {
          values = values[set];
          key = input.dataset.field;
        }

        value = values[key];

        input.value = value;
        input.defaultValue = value;
        input.blur();
      } else {
        if (input.defaultValue) {
          if (selected) selected.textContent = input.defaultValue;
        } else {
          if (selected) selected.textContent = `Select...`;

          const isVirtual = input.classList.contains(".virtual-input");
          if (isVirtual) {
            input.value = "";
          }

          const selectedOptions =
            inputWrapper.querySelectorAll(".same-as-selected");

          //Removes formatting from selected options on reset:
          if (selectedOptions) {
            selectedOptions.forEach((select) =>
              select.classList.remove("same-as-selected"),
            );
          }
        }
      }

      input.classList.remove("is-valid");
      selected?.classList.remove("is-valid");
    });

    const errors = this.form.querySelectorAll("INPUT.error");
    errors.forEach((err) => {
      err.classList.remove("error");
    });

    this.form.reset();

    //Hides error messages:
    const active = this.form.querySelectorAll(
      ".checker.active, .callout-wrapper.active",
    );
    if (active) {
      active.forEach((a) => a.classList.remove("active"));
    }

    const submitSelector = `INPUT[type="submit"]:not(:disabled)`;
    const submit = this.form.querySelector(submitSelector);
    if (submit) submit.disabled = true;

    //Removes any error styling:
    this.form.classList.remove("error");

    const formErrorMessage = this.form.querySelector(".error-message");
    if (formErrorMessage) formErrorMessage.remove();
  }
}

export class CalloutController {
  constructor({ position, element, message, eventTypes } = {}) {
    this.position = position;
    this.element = element;
    this.message = message;
    this.eventTypes = eventTypes;

    this.adjustment = 0;
    this.messageCall = "";
  }

  insertCalloutMessage() {
    const wrapper = this.element.parentElement;

    const callout = `
    <div class="callout-wrapper top">
      <div class="callout border-callout">
        <div class="callout-message"></div>
        <b class="border-notch notch"></b>
        <b class="notch"></b>
        <b class="border-fill"></b>
      </div>
    </div>`;

    const exists = wrapper.querySelector(".callout-wrapper");
    if (!exists) wrapper.insertAdjacentHTML("afterbegin", callout);
  }

  resetCalloutColors() {
    const wrapper = this.element.parentElement;
    const border = wrapper.querySelector(".border-callout");
    const borderFill = wrapper.querySelector(".border-fill");
    const highlight = wrapper.querySelector(".highlight-input");

    border.style.color = `var(--highlight)`;
    borderFill.style.backgroundColor = `var(--highlight)`;
    if (highlight) highlight.style.backgroundColor = `var(--highlight)`;
  }

  SetCalloutColors(color) {
    color = `var(--${color})`;

    const wrapper = this.element.parentElement;
    const border = wrapper.querySelector(".border-callout");
    const borderFill = wrapper.querySelector(".border-fill");
    const highlight = wrapper.querySelector(".highlight-input");

    if (color) {
      border.style.color = color;
      borderFill.style.backgroundColor = color;
      if (highlight) highlight.style.backgroundColor = color;
    } else {
      this.resetCalloutColors();
    }
  }

  setCalloutEvent() {
    this.eventTypes.forEach((eventType) => {
      const handleCallout = (e) => this.handleMessage(e);
      this.element.addEventListener(eventType, handleCallout, false);
    });
  }

  setPasswordCallout(conditions) {
    for (const key in conditions) {
      const valid = conditions[key];
      const condition = dqs(`#${key}.callout-condition`);

      if (valid) {
        condition?.classList.add("active");
      } else {
        condition?.classList.remove("active");
      }
    }
  }

  handleMongoErrors(currentTarget) {
    if (currentTarget.tagName === "INPUT") {
      currentTarget.classList.add("error");
      currentTarget.focus();
    }
  }

  handleMessage(e) {
    const { type, detail, currentTarget } = e;
    const wrapper = currentTarget.parentElement;
    const callout = wrapper.querySelector(".callout-wrapper");

    if (type === "mongoError") this.handleMongoErrors(currentTarget);

    let messageType, message, id;

    id = currentTarget.id;
    message = this.message || detail;
    messageType = message.type;
    this.messageCall = `<a id="${id}-message">${message.value}</a>`;

    //Sets the color values:
    switch (messageType) {
      case "alert":
        this.SetCalloutColors(null);
        break;
      case "error":
        this.SetCalloutColors("error");
        break;
      case "color":
        let color = message.value.toLowerCase();
        this.SetCalloutColors(color);
        break;
    }

    const toggleMessage = (callout) => {
      callout.classList.toggle("active");
    };

    const setPosition = (callout) => {
      const notch = callout.querySelector(".notch");
      const wrapperHeight = wrapper.offsetHeight;
      let offsetT = wrapperHeight + 4;

      const boxLeft = currentTarget.offsetLeft;
      const boxRight = currentTarget.offsetRight;
      const boxCenter = currentTarget.offsetWidth / 2;

      const notchCentre = notch.offsetWidth / 2;

      const calloutLeft = callout.offsetLeft;

      let indentLeft = -8;
      //Ensures element is visible inside matrix (has scroll):
      const inMatrix = callout.closest("MATRIX");
      if (inMatrix) indentLeft = 5;

      const positionLeft = boxLeft + indentLeft;

      //Handles the position:
      switch (this.position) {
        case "indent":
          callout.style.top = offsetT + "px";
          callout.style.left = positionLeft + "px";
          break;
        case "center":
          callout.style.top = offsetT + "px";
          let offsetL = -Math.abs(notchCentre + boxCenter) + positionLeft;
          let border = 2;

          callout.style.left = offsetL + border + "px";

          break;
        case "right":
          callout.style.top = offsetT + "px";
          const positionRight = boxRight - calloutLeft;
          callout.style.right = positionRight + "px";
          break;
        case "inline":
          let textWidth = getTextWidth(currentTarget) || calloutLeft;

          callout.style.left = textWidth + 10 + "px";

          let calloutHeight = callout.offsetHeight || 36;

          let vCenter = wrapperHeight - calloutHeight;
          offsetT = vCenter / 2;
          offsetT = offsetT - 1;
          callout.style.top = offsetT + "px";

          callout.classList.replace("top", "inline");
          break;
        case "above":
          callout.style.top = -Math.abs(offsetT) + "px";

          callout.style.left = positionLeft + "px";
          callout.classList.replace("top", "bottom");
          break;
      }

      //Handles the active state (visiblity) by eventType:
      switch (type) {
        case "mouseover":
          callout.classList.add("active");
          break;
        case "mouseleave":
          const isActive = callout.classList.value.includes("active");
          if (isActive) callout.classList.remove("active");
          break;
        case "click":
          toggleMessage(callout);
          break;
        case "mongoError":
          callout.classList.add("active");

          break;
        case "input":
          let valid = message.valid;
          if (valid) {
            callout.classList.remove("active");
          } else {
            callout.classList.add("active");
          }

          break;
      }
    };

    const calloutMessage = wrapper.querySelector(".callout-message");
    calloutMessage.innerHTML = this.messageCall;

    const getPosition = debounce((callout) => setPosition(callout), 200);
    getPosition(callout);
  }
}

export class FormErrorController {
  constructor(error) {
    this.error = error;
  }

  handleErrors() {
    for (const key in this.error) {
      console.log(key, this.error[key]);
    }
  }
}

export class OptionsBuilder {
  constructor({
    wrapper,
    type,
    key,
    fields,
    options = {},
    values = [],
    conditions,
    validate,
  } = {}) {
    this.wrapper = wrapper;
    this.type = type;
    this.key = key;
    this.fields = fields;
    this.options = options;
    this.values = values;
    this.conditions = conditions;
    this.validate = validate;
  }

  static sortOptions(options = [], sortBy = [], order = 1) {
    if (sortBy.length) {
      options.sort((a, b) => {
        const sortKey = sortBy.find((key) => a[key]);
        a = a[sortKey] || a;
        b = b[sortKey] || b;

        return a > b ? 1 : -1;
      });
      if (order === -1) options.reverse();
    } else {
      options.sort((a, b) => (a > b ? 1 : -1));
      if (order === -1) options.reverse();
    }
    return options;
  }

  static handleMatrixCallout(input, position = "indent") {
    const options = {
      position: position,
      element: input,
      message: null,
      eventTypes: ["mongoError"],
    };

    const calloutMesage = new CalloutController(options);
    calloutMesage.insertCalloutMessage();
    calloutMesage.setCalloutEvent();

    input.addEventListener("keydown", (e) => {
      const { currentTarget, key } = e;
      const inputWrapper = currentTarget.closest(".input-wrapper");
      const callout = inputWrapper.querySelector(".callout-wrapper");
      const isActive = Array.from(callout.classList).includes("active");
      const enter = key === "Enter";
      const escape = key === "Escape";

      //Removes callout on keydown:
      if (isActive && !enter) {
        callout.classList.remove("active");
      }

      const matrixItem = currentTarget.closest(
        ".matrix-item, .add-option-wrapper",
      );
      //Handles matrix item submit:
      if (enter) {
        const submitSelector = `BUTTON[type="submit"]:not(:disabled)`;
        const submit = matrixItem.querySelector(submitSelector);
        submit?.click();
      } else if (escape) {
        const resetSelector = `BUTTON[type="reset"]`;
        const reset = matrixItem.querySelector(resetSelector);
        reset?.click();
      }
    });
  }

  createMatrixInputs(wrapper, values) {
    let { _id, ...remainingValues } = values;

    const sortByKey = (object) => {
      return Object.keys(object)
        .sort()
        .reduce((obj, key) => {
          obj[key] = remainingValues[key];
          return obj;
        }, {});
    };

    remainingValues = sortByKey(remainingValues);

    //Inserts text inputs:
    const insertTextInputs = (key, values) => {
      let value, name, field, placeholder, limit;
      let icon = "";
      let status = "";
      let capture = "";

      value = values[camelCase(key)] || "";

      field = this.fields.field;

      limit = 16;

      switch (field) {
        case "users":
          name = kababCase(key);

          if (key === "email") {
            limit = 41;
            placeholder = "Enter user email...";
          }

          status = values?.status || "";

          switch (status) {
            case "invited":
              icon = `<div class="input-icon">${pageIcons.invitedIcon}</div>`;
              capture = "capture";
              break;
            case "registered":
              icon = `<div class="input-icon immutable">${pageIcons.lockIcon}</div>`;
              break;
            default:
              capture = "capture";
              break;
          }

          break;
        case "sizes":
          placeholder = `e.g. Jackets...`;
          name = kababCase(key);
          capture = "capture";
          break;
        case "exchange-rates":
          name = kababCase(key);
          if (key === "currency") {
            placeholder = "Currency";
          } else if (key === "exchange-rate") {
            placeholder = "Enter rate...";
            capture = "capture";
          }
          break;

        case "composition":
          limit = 31;
        default:
          name = kababCase(field);
          placeholder = `Enter ${sentanceCase(field).toLowerCase()}...`;
          capture = "capture";
          break;
      }

      const input = `<div class="input-wrapper ${key}">
        <input type="text" 
              data-key="${camelCase(key)}"
              class="matrix-input ${capture} ${status}" 
              name="${name}" 
              aria-label="${name}" 
              value="${value}" 
              placeholder="${placeholder}"
              maxlength="${limit}"
              autocomplete="off"
              name="notASearchField"
              required
              disabled
            >
            ${icon}
            </div>`;

      wrapper.insertAdjacentHTML("beforeend", input);
    };

    const insertDropdownInputs = (fields) => {
      //Creates dropdown & checkbox inputs:

      if (fields.length) {
        this.createDropdowns(wrapper, fields, false);

        const setDefaultState = () => {
          const dropdown = wrapper.querySelector(".custom-dropdown");

          const input = dropdown.querySelector("INPUT");
          dropdown.setAttribute("tabIndex", "-1");
          if (_id) input.dataset._id = _id;
        };

        setDefaultState();
      }
    };

    const matrix = this.fields?.listset || this.fields?.matrix;

    for (let key in remainingValues) {
      for (const fieldType in matrix) {
        let field = kababCase(key);
        const type = matrix[fieldType].includes(field) ? fieldType : null;

        switch (type) {
          case "text":
            insertTextInputs(field, remainingValues);
            break;
          case "dropdown":
          case "checkbox":
            const dropdown = {
              type: type,
              options: this.options[key],
              field: field,
              value: remainingValues[key],
              disabled: true,
            };

            insertDropdownInputs([dropdown]);
            break;
          case "bridge":
            this.insertBridge(wrapper, field, remainingValues);
            break;
        }
      }
    }
  }

  disableMatrixInputs(activeMatrix, reset = true) {
    activeMatrix.forEach((active) => {
      const inputs = active.querySelectorAll(".matrix-input");

      const dropdowns = active.querySelectorAll(".custom-dropdown");
      const callouts = active.querySelectorAll(".callout-wrapper.active");

      const getOption = (id, key) => {
        const options = this.values;
        key = camelCase(key);

        let option = options.find((option) => option._id.toString() === id);
        let value;

        if (option[key]) {
          //Finds standard key/value pair:
          value = option[key];
          value = value?.value || value;
        } else if (option.sizeBridge) {
          //Finds sizebridge:
          key = Number(key);
          value = option.sizeBridge.find((val) => val.position === key);
          value = value?.value || "";
        }
        return value;
      };

      if (inputs.length) {
        inputs.forEach((input) => {
          const optionWrapper = input.closest(".matrix-item");

          const optionId = optionWrapper?.dataset._id;
          const optionKey = input.dataset.key || input.dataset.position;

          const resetTextInputs = () => {
            let original = "";
            if (optionId) {
              original = getOption(optionId, optionKey);
            }

            input.value = original;
            input.dataset.value = original;
          };

          if (reset) resetTextInputs();

          switch (input.name) {
            case "currency":
              input.classList.remove("capture", "is-valid");
              break;
            default:
              input.classList.remove("error", "is-valid", "immutable");
              break;
          }

          input.disabled = true;
          input.blur();
        });
      }

      if (dropdowns.length) {
        dropdowns.forEach((dropdown) => {
          dropdown.classList.add("disabled");
          dropdown.setAttribute("tabIndex", "-1");

          const selected = dropdown.querySelector(".dropdown-selected");
          const input = dropdown.querySelector("INPUT.capture");
          const optionWrapper = input.closest(".matrix-item");
          const options = dropdown.querySelectorAll(".option");

          const resetDropdowns = () => {
            const optionId = optionWrapper?.dataset._id;
            const optionKey = input.dataset.key;

            const findOption = (options, selector) => {
              options = Array.from(options);
              return options.find((option) => {
                const classList = Array.from(option.classList);
                const optionTxt = option.textContent.toLowerCase();

                const classMatches = classList.includes(selector);
                const nameMatches = optionTxt === selector;

                return classMatches || nameMatches;
              });
            };

            let original = "";

            const option = findOption(options, "same-as-selected");
            option?.classList.remove("same-as-selected");

            if (optionId) {
              original = getOption(optionId, optionKey);

              const option = findOption(options, original);
              option?.classList.add("same-as-selected");
              input.value = original;
            } else {
              original = "Select...";
              input.value = "";
            }

            selected.textContent = sentanceCase(original);
          };

          if (reset) resetDropdowns();

          selected.classList.remove("error", "is-valid");
        });
      }

      if (callouts.length) {
        callouts.forEach((callout) => {
          callout.classList.remove("active");
        });
      }

      active.classList.remove("active");

      const editButton = active.querySelector(`BUTTON[name="edit-button"]`);
      const deleteButton = active.querySelector(`BUTTON[name="delete-button"]`);
      const submitButton = active.querySelector(`BUTTON[name="submit-button"]`);
      const resetButton = active.querySelector(`BUTTON[name="reset-button"]`);

      submitButton.disabled = true;
      resetButton.disabled = true;

      if (editButton) {
        editButton.disabled = false;
        deleteButton.disabled = false;
      }
    });
  }

  createMatrixRows(wrapper, values = []) {
    let sortKeys = ["email", "currency", "value", "name"];

    values = this.constructor.sortOptions(values, sortKeys);

    //Inserts input Wrapper:
    const fieldsWrapper = wrapper.querySelector(".fields-wrapper");

    const isSizeBridge = values[0]?.sizeBridge;
    let insertWrapper = fieldsWrapper;
    let position = "beforeend";

    //Determines the inserted position:
    if (values.length === 1) {
      if (isSizeBridge) {
        const matrixItems = fieldsWrapper.children;
        const defaultSizes = this.values.filter(
          (v) => v.type.toLowerCase() === "default",
        ).length;
        insertWrapper = matrixItems[defaultSizes - 1];
        position = "afterend";
      } else {
        insertWrapper = fieldsWrapper;
        position = "afterbegin";
      }
    } else {
      if (isSizeBridge) {
        let sortKeys = ["type"];
        values = this.constructor.sortOptions(values, sortKeys, -1);
      }
    }

    const scrollParams = {
      scrollAxis: "y",
      flowWrapper: fieldsWrapper,
    };

    const flowStart = new AddScrollFlow(scrollParams);
    const max = values.length;

    if (max) {
      values.forEach(({ _id, ...inputValues } = {}, index) => {
        let dataset;

        _id = _id?.toString();
        dataset = `data-_id="${_id}"`;

        insertWrapper.insertAdjacentHTML(
          position,
          `<div class="matrix-item" ${dataset}></div>`,
        );

        const setWrapper = wrapper.querySelector(`[${dataset}].matrix-item`);
        this.createMatrixInputs(setWrapper, inputValues);

        let attributes = "";

        const defaultRow = inputValues?.type?.toLowerCase() === "default";
        if (defaultRow) {
          attributes = "disabled";
        }

        const insertSubmitButtons = () => {
          let submitButtons;

          if (defaultRow) {
            submitButtons = `
            <div class="submit-wrapper immutable-icon">${pageIcons.lockIcon}</div>
          `;
          } else {
            submitButtons = `<div class="submit-wrapper submit-icons active">
          <button name="edit-button" aria-label="edit-input" type="button" value="edit" ${attributes}>${pageIcons.editIcon}</button>
          <button name="delete-button" aria-label="delete-input" type="button" value="delete" ${attributes}>${pageIcons.deleteIcon}</button>
          <button name="submit-button" aria-label="submit-change" type="submit" value="update" disabled>${pageIcons.saveIcon}</button>
          <button name="reset-button" aria-label="reset-change" type="reset" value="reset" disabled>${pageIcons.cnxIcon}</button>
          </div>`;
          }
          setWrapper.insertAdjacentHTML("beforeend", submitButtons);
        };

        insertSubmitButtons();

        const inputs = setWrapper.querySelectorAll("INPUT.capture");

        const lastRow = max === index + 1;
        const calloutPosition = lastRow ? "above" : "indent";

        inputs.forEach((input) => {
          OptionsBuilder.handleMatrixCallout(input, calloutPosition);
        });

        const editButton = setWrapper.querySelector(
          `BUTTON[name="edit-button"]`,
        );
        const deleteButton = setWrapper.querySelector(
          `BUTTON[name="delete-button"]`,
        );
        const resetButton = setWrapper.querySelector(
          `BUTTON[name="reset-button"]`,
        );

        const toggleForm = (e) => {
          const { currentTarget } = e;

          const activateMatrixInputs = () => {
            const activate = currentTarget.closest(".matrix-item");
            const inputs = activate.querySelectorAll("INPUT");
            const dropdowns = activate.querySelectorAll(".custom-dropdown");

            if (inputs.length) {
              inputs.forEach((input) => {
                const classList = Array.from(input.classList);
                const name = input.name;

                switch (name) {
                  case "email":
                    if (classList.includes("registered")) {
                      input.classList.add("immutable");
                    } else {
                      input.disabled = false;
                    }
                    break;
                  case "currency":
                    input.classList.add("capture", "is-valid");
                    input.dataset.value = input.value
                      .replace(/[^a-zA-Z0-9.,]/g, "")
                      .trim();
                    break;
                  default:
                    input.disabled = false;
                    break;
                }
              });

              const activeInputs = activate.querySelectorAll(
                "INPUT:not(:disabled)",
              );
              const addFocus = activeInputs[0];
              addFocus.focus();
              const end = addFocus.value.length;
              addFocus.setSelectionRange(end, end);
            }

            if (dropdowns.length) {
              dropdowns.forEach((dropdown) => {
                dropdown.classList.remove("disabled");
                dropdown.setAttribute("tabIndex", "0");
              });
            }

            activate.classList.add("active");

            const submitButton = setWrapper.querySelector(
              `BUTTON[name="submit-button"]`,
            );

            submitButton.disabled = true;
            resetButton.disabled = false;
            editButton.disabled = true;
            deleteButton.disabled = true;

            flowStart.setPosition();
          };

          const activeMatrix = wrapper.querySelectorAll(".matrix-item.active");

          if (activeMatrix.length) {
            this.disableMatrixInputs(activeMatrix);
          }

          if (currentTarget.name !== "reset-button") {
            activateMatrixInputs();
          }
        };

        if (editButton) {
          editButton.addEventListener("click", (e) => {
            toggleForm(e);

            const activeAddOption = wrapper.querySelectorAll(
              ".add-option-wrapper.active",
            );

            if (activeAddOption.length) {
              //Disables active add option wrapper:
              this.disableMatrixInputs(activeAddOption);
            }
          });

          resetButton.addEventListener("click", (e) => {
            toggleForm(e);

            const activeAddOption = wrapper.querySelectorAll(
              ".add-option-wrapper.active",
            );

            if (activeAddOption.length) {
              //Disables active add option wrapper:
              this.disableMatrixInputs(activeAddOption);
            }
          });

          deleteButton.addEventListener("click", (e) => {
            e.preventDefault();
            const { currentTarget } = e;
            const form = currentTarget.closest("FORM");

            const selectors = ".add-option-wrapper.active, .matrix-item.active";
            const activeMatrix = wrapper.querySelectorAll(selectors);

            if (activeMatrix.length) {
              this.disableMatrixInputs(activeMatrix);
            }

            currentTarget.setAttribute("type", "submit");
            form.requestSubmit(currentTarget);
          });
        }
      });
    }

    flowStart.createFlow();
  }

  static updateMatrix(wrapper, data) {
    let { _id, ...values } = data;
    _id = _id.toString();

    const itemWrapper = wrapper.querySelector(`[data-_id="${_id}"]`);
    console.log("updateMatrix/values", values);
    for (const key in values) {
      const isSizeBridge = key === "sizeBridge";
      if (isSizeBridge) {
        const sizeBridge = values[key];
        sizeBridge.forEach(({ position, value, operation } = {}) => {
          const selector = `INPUT[data-position="${position}"]`;
          const input = itemWrapper.querySelector(selector);
          input.value = value;
          input.defaultValue = value;
        });
      } else {
        const selector = `INPUT[data-key="${key}"]`;
        const input = itemWrapper.querySelector(selector);
        if (input) {
          const value = values[key];
          input.value = value;
          input.defaultValue = value;

          const className = input.className;
          if (!className.includes("virtual-input")) {
            input.disabled = true;
          }
        }
      }
    }
  }

  addMatrixValidation(form, data) {
    let { _id, ...values } = data;
    _id = _id.toString();

    const itemWrapper = form.querySelector(`[data-_id="${_id}"]`);

    for (const key in values) {
      let selector = "";

      if (key === "sizeBridge") {
        selector = `INPUT[name="size"]`;
      } else {
        selector = `INPUT[data-key="${key}"]`;
      }
      const inputs = itemWrapper.querySelectorAll(selector);

      if (inputs) {
        const validateParams = {
          form: form,
          inputs: inputs,
          validate: this.validate,
          conditions: this.conditions,
        };

        const validateInputs = new ValidationController(validateParams);
        validateInputs.handleValidation();
      }
    }
  }

  insertAddToMatrix(wrapper) {
    const field = this.fields.field;
    const id = kababCase(field);
    const buttonKey = titleCase(field);

    let buttonText;

    switch (this.key) {
      case "users":
        buttonText = "Invite";
        break;
      default:
        buttonText = "Add";
        break;
    }

    let value = buttonText + " " + buttonKey;
    let name = kababCase(value);

    wrapper.insertAdjacentHTML(
      "afterbegin",
      `<div class="add-option-wrapper">
        <div class="input-wrapper add-option"><input type="button" name="${name}" tabindex="0" class="option-input" value="+ ${value}"></div>
        <div class="add-option-item"></div>
      </div>
      `,
    );

    const Matrii = this.fields.listset || this.fields.matrix;

    let fields = {};
    for (const field in Matrii) {
      for (const set of Matrii[field]) {
        fields[camelCase(set)] = "";
      }
    }

    const optionWrapper = wrapper.querySelector(`.add-option-item`);
    this.createMatrixInputs(optionWrapper, fields);

    const insertSubmitButtons = () => {
      const submitButtons = `<div class="submit-icons submit-wrapper">
      <button name="submit-button" aria-label="submit-${id}" type="submit" value="add" disabled>${pageIcons.saveIcon}</button>
      <button name="reset-button" name="reset-form" aria-label="reset-option" type="reset" value="reset" disabled>${pageIcons.cnxIcon}</button>
      </div> <div class="ys-fill"></div>`;

      optionWrapper.insertAdjacentHTML("beforeend", submitButtons);
    };

    insertSubmitButtons();

    const addOptionWrapper = wrapper.querySelector(".add-option-wrapper");
    const addOptionButton = wrapper.querySelector("INPUT.option-input");
    const addOptionInputs = optionWrapper.querySelectorAll("input.capture");
    const resetButton = wrapper.querySelector("BUTTON[type=reset]");

    addOptionInputs.forEach((input) => {
      OptionsBuilder.handleMatrixCallout(input);
    });

    addOptionButton.addEventListener("click", (e) => {
      e.preventDefault();

      addOptionWrapper.classList.add("active");

      const inputs = optionWrapper.querySelectorAll("INPUT.matrix-input");

      if (inputs.length) {
        inputs.forEach((input) => {
          input.disabled = false;
        });
        inputs[0].focus();
      }

      const dropdowns = optionWrapper.querySelectorAll(".custom-dropdown");
      if (dropdowns.length) {
        dropdowns.forEach((dropdown) => {
          dropdown.classList.remove("disabled");
          dropdown.setAttribute("tabIndex", "0");
        });
      }

      const activeMatrix = wrapper.querySelectorAll(".matrix-item.active");

      if (activeMatrix.length) {
        this.disableMatrixInputs(activeMatrix);
      }

      resetButton.disabled = false;
    });

    const handleReset = (e) => this.disableMatrixInputs([addOptionWrapper]);
    resetButton.addEventListener("click", handleReset);
  }

  insertBridge(wrapper, field, values) {
    const bridge = `<div data-field="${field}" class="bridge-wrapper"></div>`;
    wrapper.insertAdjacentHTML("beforeend", bridge);

    const bridgeWrapper = wrapper.querySelector(".bridge-wrapper");

    const matrix = wrapper.closest("MATRIX");
    matrix.classList.add("bridge");

    //Qty of cells per row:
    let qty = Math.max(
      ...this.values.map(({ sizeBridge } = {}) => sizeBridge.length),
    );

    let { sizeBridge, type = "new" } = values;
    wrapper.classList.add(type.toLowerCase());

    let placeholder = "...";
    let size;
    if (sizeBridge === "") {
      sizeBridge = [];
      size = 50;
      placeholder = size;
    }

    for (let i = qty; i--; ) {
      let value;

      if (!isNaN(Number(size))) {
        size -= 2;
        placeholder = size + "...";
      } else {
        placeholder = "...";
      }

      value = sizeBridge.find(({ position = null } = {}) => position === i);
      value = value?.value || "";

      const input = `<input class="matrix-input capture" name="size" data-position="${i}" value="${value}" placeholder="${placeholder}" autocomplete="off" disabled>`;
      const sizeCell = `<div class="input-wrapper bridge">${input}</div>`;
      bridgeWrapper.insertAdjacentHTML("afterbegin", sizeCell);
    }
  }

  //Creates a table of editable grouped inputs:
  createMatrix(form) {
    let field = this.fields.field;
    let id = field || this.key;
    id = kababCase(id);

    const insertMatrix = () => {
      const name = sentanceCase(id);
      const attributes = this.fields?.matrix ? "matrix" : "";

      form.insertAdjacentHTML(
        "beforeend",
        `<matrix id="${id}-matrix" class="${attributes}">    
            <legend><span>${name}</span></legend>
            <div class="matrix-scroll-wrapper">
              <div class="fields-wrapper"></div>
            </div>
        </matrix>`,
      );

      this.createMatrixRows(form, this.values);
    };

    const insertHeader = () => {
      let matrixFields = this.fields?.matrix;

      //Has Header:
      if (matrixFields) {
        const legend = form.querySelector("LEGEND");
        let fields = Object.values(matrixFields).flat();

        fields = fields
          .map(
            (field) =>
              `<div class="matrix-head-cell ${field}">${titleCase(field)}</div>`,
          )
          .join(" ");

        const header = `<div class="matrix-header">${fields}<div class="submit-icons edit active">...</div></div>`;
        legend.insertAdjacentHTML("afterend", header);
      }
    };

    insertMatrix();
    insertHeader();

    const scrollWrapper = form.querySelector(".matrix-scroll-wrapper");
    this.insertAddToMatrix(scrollWrapper);
  }

  createFieldset(form, fieldsets) {
    fieldsets.forEach(({ key, ...fields } = {}) => {
      let id = kababCase(key);

      const insertFieldset = () => {
        const name = sentanceCase(id);

        form.insertAdjacentHTML(
          "beforeend",
          `<fieldset id="${id}-fieldset" data-set="${key}">
            <legend>${name}</legend>      
          </fieldset>
          `,
        );
      };

      insertFieldset();

      const fieldset = form.querySelector(`#${id}-fieldset`);

      let dropdowns = [];
      for (const type in fields) {
        switch (type) {
          case "text":
            let textFields = this.mergeInputData(type, fields[type], key);
            this.createTextInputs(fieldset, textFields);
            break;

          case "checkboxes":
          case "dropdown":
            //Merges dropdowns and checkboxes:
            let dropdownFields = this.mergeInputData(type, fields[type], key);
            dropdowns = [...dropdowns, ...dropdownFields];
            break;
        }
      }

      if (dropdowns.length) {
        this.createDropdowns(fieldset, dropdowns);
      }
    });
  }

  //Creates (single) options to be inserted into dropdowns:
  static createOptions(wrapper, options) {
    //Sorts values && nested optionts:

    options = OptionsBuilder.sortOptions(options, ["value"]);

    options?.forEach((option) => {
      let value = "";
      let dataset = "";
      let ariaLabel = "";

      if (typeof option === "string") {
        value = option;
        ariaLabel = option.toLowerCase();
        dataset = `data-value="${value}"`;
      } else {
        value = option.value;
        ariaLabel = option.value.toLowerCase();
        for (const key in option) {
          dataset = dataset + `data-${key}="${option[key]}" `;
        }
      }

      ariaLabel = ariaLabel.replace(/[^\w\s]/gi, "").trim();

      wrapper.insertAdjacentHTML(
        "beforeend",
        `<div ${dataset} tabindex="1" class="option" aria-label="${ariaLabel}">${value}</div>`,
      );
    });

    //Targets each option in the dropdown menu:
    const eachOption = wrapper.childNodes;

    //Targets the dropdown group wrapper:
    const group = wrapper.closest(":where(.input-wrapper)");
    const selected = group.querySelector(".dropdown-selected");

    //Adds & removes the highlight for each element:
    eachOption.forEach((option) => {
      const selectedTxt = selected.textContent;
      const optionTxt = option.textContent;

      if (selectedTxt.toLowerCase() === optionTxt.toLowerCase()) {
        option.classList.add("same-as-selected");
      }

      option.addEventListener("mousedown", (e) => {
        e.preventDefault();
        const { currentTarget } = e;

        const inputWrapper = currentTarget.closest(".input-wrapper");

        const input = inputWrapper.querySelector(`INPUT.capture`);

        const text = currentTarget.textContent;
        const dataset = currentTarget.dataset;

        for (const key in dataset) {
          input.dataset[key] = dataset[key];
        }

        const selectedTxt = selected.textContent;

        const isValid = selectedTxt !== text;

        if (isValid) {
          selected.classList.add("is-valid");
        }

        selected.textContent = text;
        input.value = text;

        const event = new InputEvent("input", { data: text, value: text });
        input.dispatchEvent(event);

        const wrapper = option.closest(".custom-dropdown");
        const prevOption = wrapper.querySelector(".same-as-selected");

        prevOption?.classList.remove("same-as-selected");
        option.classList.add("same-as-selected");

        wrapper?.classList.remove("active");
      });
    });
  }

  //Creates (multiple) checkbox options to be inserted into dropdowns:
  static createCheckboxes(wrapper, field, options) {
    //Creates an array of checkboxes to insert into the dropdown container:
    options?.sort();

    //Targets the dropdown group wrapper:
    const group = wrapper.closest(":where(.input-wrapper)");
    const input = group.querySelector(`INPUT[name="${field}"]`);
    let values = [];

    if (input?.value) {
      values = input.value.split(",");
    }

    options.forEach((option) => {
      let value = "";
      let dataset = "";

      if (typeof option === "string") {
        value = option;

        dataset = `data-value="${value}"`;
      } else {
        value = option.value;

        for (const key in option) {
          dataset = dataset + `data-${key}="${option[key]}" `;
        }
      }

      const checkbox = `<div class='checker' class="option" tabindex="1" aria-label="${value.toLowerCase()}"><label>
          <input ${dataset} class="dropdown-cb" name="${value}" type="checkbox" value="${value}" />
          <span class="checkmark"></span>
          <span>${value}</span></label></div>`;

      wrapper.insertAdjacentHTML("beforeend", checkbox);
    });

    const checkValues = () => {
      values.forEach((value) => {
        const checkedBox = wrapper.querySelector(`input[value="${value}"]`);

        if (checkedBox) {
          const checker = checkedBox.closest(".checker");
          checkedBox.checked = true;
          checker.classList.add("active");
        }
      });
    };

    if (values.length) checkValues();

    const handleCheckboxes = (e) => {
      e.preventDefault();
      const { currentTarget } = e;

      if (currentTarget.classList.contains("active")) {
        currentTarget.classList.remove("active");
      } else {
        currentTarget.classList.add("active");
      }

      const dropdown = currentTarget.closest(".custom-dropdown");
      const selected = dropdown.querySelector(".dropdown-selected");
      const input = dropdown.querySelector(".virtual-input.capture");
      const optionsWrapper = currentTarget.closest(".options-wrapper");

      let checkboxes = optionsWrapper.querySelectorAll(".checker.active");

      let datasets = [];
      let values = [];
      let colorIcons = [];
      for (const checkbox of checkboxes) {
        const input = checkbox.querySelector("input");
        let { value, dataset } = input;
        if (checkbox.classList.contains("active")) {
          input.checked = true;
        } else {
          input.checked = false;
        }

        let attributes = "";
        let text = "";
        switch (field) {
          case "supplier-type":
            attributes = `id="type-${value.toLowerCase()}" class="type-box"`;
            text = value;
            dataset = dataset.value;
            break;
          case "colors":
            attributes = `id="color-${value}" class="color-box" style="background-color:var(--${value.toLowerCase()});"`;
            break;
        }

        colorIcons.push(`<span ${attributes}>${text}</span>`);
        datasets.push(dataset);
        values.push(value);
      }

      if (values.length) {
        selected.innerHTML = colorIcons.join("");
        input.value = values.join(",");
        input.dataset.value = JSON.stringify(datasets);
        selected.classList.add("is-valid");
        input.classList.add("is-valid");
      } else {
        selected.innerHTML = "Select...";
        selected.classList.remove("is-valid");
        input.classList.remove("is-valid");
        input.classList.remove("is-valid");
        input.value = "";
        input.dataset.value = "";
      }

      const event = new InputEvent("input", { data: values.join(",") });
      input.dispatchEvent(event);
    };

    const checkboxes = wrapper.querySelectorAll(".checker");
    checkboxes.forEach((checkbox) =>
      checkbox.addEventListener("click", handleCheckboxes),
    );
  }

  createTextInputs(form, fields, label = true) {
    fields.forEach(({ field, value = "", set = null } = {}) => {
      field = kababCase(field);

      const key = camelCase(field);
      const tagName = form.tagName;

      //Removes current from password:
      let labelText = field.replace("current", "").trim();
      labelText = sentanceCase(labelText);

      let inputLabel = "";

      let placeholder = `Enter ${labelText.toLowerCase().trim()}...`;
      let attributes = "";
      let inputClasses = "form-input capture";
      let contextual = "";
      let tabindex = "0";
      let type = "text";
      let viewPassword = "";

      let datasets = "";
      let valset = "";
      let maxField = this.conditions.maxlength.find((f) => f[0] === field);
      let maxlength = "";

      if (maxField) maxlength = `maxlength="${maxField[1] + 1}"`;

      switch (field) {
        case "email":
          placeholder = placeholder.replace("Enter", "Enter your");
          attributes = `name="${field}" autocapitalize="none" autocomplete="username" aria-invalid="false"`;
          type = "email";
          break;
        case "confirm-email":
          placeholder = placeholder.replace("Enter", "Confirm your");
          attributes = `name="email" autocapitalize="none" autocomplete="username" aria-invalid="false"`;
          type = "email";
          break;
        case "supplier-email":
          attributes = `name="${field}" autocapitalize="none" autocomplete='off' aria-invalid="false"`;
          type = "email";
          break;
        case "company-email":
          attributes = `name="${field}" autocapitalize="none" autocomplete="${field}" aria-invalid="false"`;
          type = "email";
          break;
        case "company-name":
          attributes = `name="${field}" autocomplete="organization"`;
          break;

        case "current-password":
        case "new-password":
          viewPassword = `<button id="toggle-password" class="text-button" type="button" aria-label="Show password as plain text. Warning: this will display your password on the screen.">Show password</button>`;
          type = "password";
          attributes = `name="${field}" autocomplete="${field}"`;
          break;

        case "confirm-password":
          placeholder = sentanceCase(placeholder.replace("Enter", ""));
          type = "password";
          attributes = `name="${field}" autocomplete="${field}"`;
          break;

        case "supplier-id":
        case "sku":
          placeholder = "Loading...";
          attributes = `name="${field}" disabled`;
          inputClasses = "virtual-input capture system";
          contextual = `<div id="sku-new" class="new-sku-input"><span class="new-sku-txt"></span><div class="immutable-icon">${pageIcons.lockIcon}</div></div>`;
          tabindex = "-1";
          break;

        case "item-name":
          attributes = `name="notASearchField" autocomplete="off"`;
          break;

        case "phone":
          type = "tel";
          attributes = `autocomplete="tel" name="tel"`;
          placeholder = "Enter phone number...";
          break;
        case "first-name":
          attributes = `autocomplete="given-name" name="given-name"`;
          break;
        case "last-name":
          attributes = `autocomplete="family-name" name="family-name"`;
          break;
        case "address-one":
          attributes = `autocomplete="address-line1" name="address-line-one"`;
          placeholder = `Enter address...`;
          break;
        case "address-two":
          attributes = `autocomplete="address-line2" name="address-line-two"`;
          placeholder = `Enter address...`;
          inputClasses = "form-input optional";

          break;
        case "postal-code":
          labelText = "Postcode";
          attributes = `autocomplete="postal-code" name="postal-code"`;
          placeholder = `Enter postcode...`;
          break;
        case "supplier-cost":
        case "unit-cost":
          attributes = `name="${field}" autocomplete="off"`;
          break;
        case "lead-time":
          attributes = `name="${field}"`;
          contextual = `<span class="sub-text"> wks</span>`;
          break;
        default:
          attributes = `name="${field}" autocomplete="off"`;
          break;
      }

      if (label) {
        inputLabel = `<div class="label-wrapper">
        <label for="${field}">${labelText}:</label>
        ${viewPassword}
      </div>`;
      }

      if (value) {
        valset = `data-value="${value}"`;
      }

      if (set) {
        //Fieldsets are assumed to be nested fields:
        datasets = `data-key="${camelCase(set)}" data-field="${key}" ${valset}`;
      } else {
        datasets = `data-key="${key}" ${valset}`;
      }

      const inputWrapper = `<div class="input-wrapper">
        ${inputLabel}
        <input id="${field}" class="${inputClasses}"
          type="${type}"
          tabindex="${tabindex}"
          placeholder="${placeholder}"
          ${datasets}
          ${attributes}
          value="${value}"
          ${maxlength}
          />
        ${contextual}
      </div>`;

      switch (tagName) {
        case "FORM":
        case "FIELDSET":
        case "STAGE":
          form.insertAdjacentHTML("beforeend", inputWrapper);
          break;
        case "TR":
          const cell = this.row.querySelector(`TD[data-key="${field}"]`);
          cell.innerHTML = inputWrapper;

          const subText = cell.querySelector(".sub-text");

          if (subText) {
            const input = cell.querySelector("INPUT");
            const offset = getTextWidth(input, input.value, 10);

            subText.style.left = offset + "px";
          }

          break;
      }

      const input = form.querySelector(`INPUT#${field}`);

      const options = {
        element: input,
        message: null,
        eventTypes: ["mongoError"],
        position: "indent",
      };

      const calloutMesage = new CalloutController(options);
      calloutMesage.insertCalloutMessage();
      calloutMesage.setCalloutEvent();
    });

    this.insertPasswordToggle(form);
  }

  createSwitchInputs(form, fields) {
    fields.forEach(({ field, value = true } = {}) => {
      let id = kababCase(field);
      let datasets = "";
      let attributes = "";

      const submitForm = this.fields?.submit;

      let inputClasses = "";

      if (submitForm) {
        inputClasses = "capture is-valid";
      }

      datasets = `data-value="${value}"`;

      if (value) attributes = "checked";

      datasets = datasets + `data-key="${camelCase(field)}"`;

      form.insertAdjacentHTML(
        "beforeend",
        `<div class="switch-wrapper">
          <div class="switch-label ">${sentanceCase(field)}:</div>
          <label class="switch ${id}" tabIndex="0">
            <input id="${id}" class="${inputClasses}" name="${id}" type="checkbox" ${datasets} value="${value}" ${attributes}/>
            <span class="toggle"></span>
          </label>
      </div>`,
      );

      const toggleSwitch = form.querySelector(`INPUT#${id}`);

      toggleSwitch.addEventListener(
        "change",
        (e) => {
          e.preventDefault();
          const { currentTarget } = e;

          const isValid = value !== currentTarget.checked.toString();

          currentTarget.value = currentTarget.checked;
          currentTarget.dataset.value = currentTarget.checked;

          if (isValid) {
            currentTarget.classList.add("is-valid");
            currentTarget.classList.add("capture");

            if (!submitForm) {
              this.submitForm(e);
            }
          } else {
            currentTarget.classList.remove("is-valid");
            currentTarget.classList.remove("capture");
          }
        },
        false,
      );
    });
  }

  createDropdowns(form, fields, label = true) {
    fields = OptionsBuilder.sortOptions(fields, ["field"]);

    const loadArrayValues = (field, values) => {
      values = values.map((value) => {
        let attributes = "";
        let text = "";

        switch (field) {
          case "supplier-type":
            attributes = `id="type-${value.toLowerCase()}" class="type-box"`;
            text = value;
            break;
          case "colors":
            let color = value.value;
            attributes = `id="color-${color}" class="color-box" style="background-color:var(--${color.toLowerCase()});"`;
            break;
        }

        return `<span ${attributes}>${text}</span>`;
      });

      return values.join("");
    };

    fields.forEach(
      async ({ type, options, field, value = "", disabled = false } = {}) => {
        const key = camelCase(field);
        const tagName = form.tagName;
        const dropdownType = tagName === "TR" ? "table" : "form";

        //Adds the scrollable icon:
        const scrollIcon = `<div class="scroll-me">${pageIcons.arrowIcon}</div>`;
        const qty = options?.length || 0;
        const scrollable = qty > 6 ? scrollIcon : "";

        let datasets = "";
        let set = form?.dataset?.set;

        if (set) {
          datasets = `data-key="${set}" data-field="${key}"`;
        } else {
          datasets = `data-key="${key}"`;
        }

        let inputLabel = "";
        let attributes = "";
        let selectedOption = value || `Select...`;
        let status = disabled ? "disabled" : "";
        let hasValue = "";

        if (label) {
          //Stops the duplication of ids on matrii and rows.
          attributes = `id="${field}"`;

          inputLabel = `<div class="label-wrapper">
                 <label for="${field}">${sentanceCase(field)}:</label>
              </div>`;
        }

        let isString = (value) => typeof value === "string" && value.length;
        if (isString(value)) {
          hasValue = "has-value";
        } else if (Array.isArray(value)) {
          selectedOption = loadArrayValues(field, value);
          value = value.map(({ value }) => value);
          hasValue = "has-value";
        } else if (typeof value === "object" && !Array.isArray(value)) {
          selectedOption = value.value;
          hasValue = "has-value";
        }

        const inputWrapper = `<div class="input-wrapper ${key}">
        ${inputLabel}
          <div tabindex="0" class="custom-dropdown ${field} ${dropdownType}-dropdown ${status}">
            <div class="dropdown-selected ${dropdownType}-selected ${hasValue}">${selectedOption}</div>
            <input ${attributes} name="${field}" tabindex="-1" ${datasets} value="${value}" autocomplete="off"
            class="virtual-input capture" aria-label="${field}">
            <div id="${field}-options" class="options-wrapper" tabindex="-1"><div class="scroll-wrapper"></div></div>${scrollable}
            </div>
        </div>`;

        switch (dropdownType) {
          case "form":
            form.insertAdjacentHTML("beforeend", inputWrapper);
            break;
          case "table":
            const cell = this.row.querySelector(`TD[data-key="${field}"]`);
            cell.innerHTML = inputWrapper;
            break;
        }

        const optionsWrapper = form.querySelector(`#${field}-options`);
        const scrollWrapper = optionsWrapper.querySelector(".scroll-wrapper");

        switch (type) {
          case "checkbox":
            OptionsBuilder.createCheckboxes(scrollWrapper, field, options);
            break;
          default:
            OptionsBuilder.createOptions(scrollWrapper, options);
            break;
        }

        //Sets the height of the scroll:
        let optionHeight = getCssVariant("--row-height").replace(/\D/g, "");
        optionHeight = Number(optionHeight);
        const maxHeight = Math.ceil(optionHeight) * 6;
        optionsWrapper.style.maxHeight = maxHeight + "px";

        const dropdown = form.querySelector(
          `.${field}.${dropdownType}-dropdown`,
        );
        const scrollMe = dropdown.querySelector(".scroll-me");
        if (scrollMe) scrollMe.style.bottom = -maxHeight - 20 + "px";

        optionsWrapper.addEventListener("scroll", (e) => {
          const wrapper = optionsWrapper.parentElement;
          const scrollMe = wrapper.querySelector(".scroll-me");
          const wrapperHeight = optionsWrapper.offsetHeight;
          const scrollTop = optionsWrapper.scrollTop;

          const scrollHeight = scrollWrapper.offsetHeight;

          let percent = scrollTop / (scrollHeight - wrapperHeight);
          percent = Math.round(percent * 100);

          if (percent > 90) scrollMe?.classList.add("scroll-up");
          if (percent < 10) scrollMe?.classList.remove("scroll-up");
        });

        this.handleFormDropdowns(dropdown);
      },
    );
  }

  handleFormDropdowns(dropdown) {
    const form = dropdown.closest("FORM, TR");
    const inputWrapper = dropdown.closest(".input-wrapper");
    const optionsWrapper = inputWrapper.querySelector(`.options-wrapper`);
    const scrollWrapper = inputWrapper.querySelector(".scroll-wrapper");
    const scrollMe = inputWrapper.querySelector(".scroll-me");

    let toggle = true;
    dropdown.addEventListener("focus", (e) => {
      e.preventDefault();
      dropdown.classList.add("active");
    });

    dropdown.addEventListener("blur", (e) => {
      e.preventDefault();

      if (toggle) {
        dropdown.classList.remove("active");
        scrollMe?.classList?.remove("scroll-up");
        optionsWrapper.scrollTop = 0;
      }
    });

    dropdown.addEventListener("mousedown", (e) => {
      e.preventDefault();
      const { currentTarget, target } = e;

      let active = currentTarget.classList.contains("active");
      let isTarget = currentTarget === target;

      let hasFocus = document.activeElement;

      if (!active && isTarget) {
        currentTarget.focus();
        dropdown.classList.add("active");
      } else {
        let optionTarget = optionsWrapper.contains(target);

        if (!optionTarget) currentTarget.blur();
      }
    });

    let clickCount = -1;
    dropdown.addEventListener("keydown", (e) => {
      const { key, currentTarget } = e;
      e.preventDefault();

      let active = Array.from(currentTarget.classList).includes("active");

      let options = Array.from(scrollWrapper.children);

      let qty = options.length - 1;

      const down = "ArrowDown";
      const up = "ArrowUp";
      const enter = "Enter";
      const tab = "Tab";
      const AZ = /^[a-zA-Z]*$/;
      const escape = key === "Escape";

      const trackFocus = (count) => {
        if (count < 0) {
          clickCount = qty;
        } else if (count > qty) {
          clickCount = 0;
        }
        return clickCount;
      };

      const findNextTab = (form) => {
        const allInputs = form.querySelectorAll(".input-wrapper");
        const currentIndex = [...allInputs].indexOf(inputWrapper);
        const nextFocus = allInputs[currentIndex + 1];
        const focusDropdown = nextFocus?.querySelector(
          ".custom-dropdown, INPUT",
        );

        if (focusDropdown) {
          focusDropdown.focus();
        } else {
          const submit = form.querySelector("[type='submit']:not(:disabled)");
          const cancel = form.querySelector("[type='reset']:not(:disabled)");

          dropdown.classList.remove("active");
          dropdown.blur();

          if (submit?.disabled || !submit) {
            cancel?.focus();
          } else {
            submit?.focus();
          }
        }
      };

      let hasFocus = document.activeElement;
      let checkFocus = Array.from(hasFocus.classList);
      if (key === enter) {
        if (checkFocus.includes("option")) {
          simulateMouseEvent(hasFocus, "mousedown");
          findNextTab(form);
          toggle = true;
          dropdown.classList.remove("active");
          dropdown.blur();
        }

        if (hasFocus?.classList.contains("checker")) {
          hasFocus.click();
        }
      } else if (key === tab) {
        findNextTab(form);
        toggle = true;

        if (checkFocus.includes("checker")) {
          dropdown.classList.remove("active");
          dropdown.blur();
        }
      } else if (key === down && active) {
        toggle = false;

        ++clickCount;
        clickCount = trackFocus(clickCount);

        const option = options[clickCount];
        option.focus();
      } else if (key === up && active) {
        toggle = false;

        --clickCount;
        clickCount = trackFocus(clickCount);

        const option = options[clickCount];
        option.focus();
      } else if (AZ.test(key) && active) {
        toggle = false;
        const getIndex = ({ ariaLabel } = {}) =>
          ariaLabel.startsWith(key.toLowerCase());

        const index = options.findIndex(getIndex);

        options[index]?.focus();

        clickCount = index;
      }

      const disableScroll = (e) => {
        const { key } = e;
        if ([down, up].indexOf(key) > -1) {
          e.preventDefault();
        }
      };

      if (active) {
        window.addEventListener("keydown", disableScroll, false);
      } else {
        window.removeEventListener("keydown", disableScroll, false);
      }
    });
  }

  createButtons(form) {
    let submitButtons = this.fields.submit || [];

    if (submitButtons.length) {
      //Converts the submit fields into buttons ready to be inserted:
      submitButtons = submitButtons
        .map(
          ({ type, value } = {}) => `
         <input 
         type="${kababCase(type)}" 
         name="${kababCase(value)}"
         value="${titleCase(value)}" 
         tabindex="0" 
         class="btn"/>`,
        )
        .join("");

      let buttons = `<div class="submit-wrapper submit-buttons">${submitButtons}</div>`;

      form.insertAdjacentHTML("beforeend", buttons);

      const submit = form.querySelector("input[type='submit']");
      submit.disabled = true;
    }
  }
}

export class FormBuilder extends OptionsBuilder {
  constructor({
    wrapper,
    type,
    key,
    fields,
    options = {},
    values = [],
    conditions,
    validate,
  } = {}) {
    super();
    this.wrapper = wrapper;
    this.type = type;
    this.key = key;
    this.fields = fields;
    this.options = options;
    this.values = values;
    this.conditions = conditions;
    this.validate = validate;
  }

  insertStagedForm() {
    const insertStageWrappers = () => {
      const stagesWrapper = `<div id="${this.key}-stages" class="highlight-gradient info-stages"></div>`;
      const stagedFormWrapper = `<div id="${this.key}-wrapper" class="form-stages"></div>`;
      this.wrapper.innerHTML = stagesWrapper + stagedFormWrapper;
    };

    insertStageWrappers();

    const createStages = (wrapper, stage, index) => {
      let active = "";
      if (index === 1) active = "active";
      wrapper.insertAdjacentHTML(
        "beforeend",
        `<div class="stage-info">
        <div class="stage ${active}" data-stage="${index}">${index}</div>
        <div>
          <p class="label">STAGE ${index}</p>
          <p class="info">${sentanceCase(stage)}</p>
        </div>
      </div>`,
      );
    };

    const stagesWrapper = this.wrapper.querySelector(`#${this.key}-stages`);
    const stages = this.fields.stages;
    const submit = this.fields?.submit;

    const formWrapper = this.wrapper.querySelector(`#${this.key}-wrapper`);
    this.wrapper = formWrapper;
    this.insertForm();

    stages.forEach(({ stage, ...options }, index) => {
      index = index + 1;
      createStages(stagesWrapper, stage, index);
      this.insertStagedOptions(stage, index, options);
    });

    const form = formWrapper.querySelector("FORM");
    this.fields = { submit: submit };
    this.createButtons(form);

    const submitWrapper = form.querySelector(".submit-wrapper");
    submitWrapper.dataset.stage = 1;

    const handleStages = (e) => {
      e.preventDefault();
      const { currentTarget } = e;
      const submitWrapper = currentTarget.closest(".submit-wrapper");

      const name = currentTarget.name;
      let stage = submitWrapper.dataset.stage;
      stage = Number(stage);

      switch (name) {
        case "next":
          if (stage < stages.length) {
            stage = stage + 1;
          }
          break;
        case "go-back":
          if (stage > 1) {
            stage = stage - 1;
          }
          break;
      }

      this.setCurrentStage(submitWrapper, stage);
    };

    const stageButtons = submitWrapper.querySelectorAll(`INPUT[type="button"]`);
    stageButtons.forEach((btn) => {
      btn.addEventListener("click", handleStages);
    });
  }

  setCurrentStage(submitWrapper, stage) {
    const next = submitWrapper.querySelector(`INPUT[name="next"]`);
    const submit = submitWrapper.querySelector(`INPUT[type="submit"]`);

    submitWrapper.dataset.stage = stage;

    const formStages = dqa(".form-stage, .stage");
    formStages.forEach((formStage) => {
      let fsi = Number(formStage.dataset.stage);
      if (fsi === stage) {
        formStage.classList.add("active");
      } else {
        formStage.classList.remove("active");
      }
    });

    const stages = formStages.length / 2;
    if (stage === stages) {
      next.style.display = "none";
      submit.style.display = "block";
    } else {
      next.style.display = "block";
      submit.style.display = "none";
    }
  }

  insertForm() {
    let id = this.fields.field || this.key;
    id = kababCase(id);

    let modalSize = "";
    let title = this.fields.title || "";
    let message = this.fields?.message || "";
    let modalType = this.type;

    const setModalSize = () => {
      //Sets the layout based on the qty of fields:
      let fieldCount = [
        ...(this.fields.text || []),
        ...(this.fields.dropdown || []),
        ...(this.fields.checkbox || []),
      ].length;

      if (fieldCount >= 5) {
        modalSize = "large-modal";
      } else {
        modalSize = "small-modal";
      }
    };

    if (modalType === "modal") setModalSize();

    this.wrapper.insertAdjacentHTML(
      "beforeend",
      `<div id="${id}-modal" class="form-modal ${modalType} ${modalSize}">
    <div class="modal-inner-wrapper">
      <div class="modal-title-grid">
        <h3 class="modal-title">${title}</h3>
        <button aria-label="close-modal" class="close-button" tabindex="-1">${pageIcons.cnxIcon}</button>
      </div>
        <p class="modal-message">${message}</p>
      <form id="${id}-form"></form>
    </div></div>`,
    );

    const form = this.wrapper.querySelector(`#${id}-form`);
    form.setAttribute("tabindex", "-1");

    const modal = this.wrapper.querySelector(`#${id}-modal`);
    const closeButton = modal.querySelector(".close-button");

    closeButton.addEventListener("click", (e) => {
      modal.classList.remove("active");

      this.wrapper.classList.remove("active");

      const newItemBtn = dqs(".new-item-btn");
      if (newItemBtn) newItemBtn.classList.remove("active");

      const validate = new ValidationController({ form: form });
      validate.resetForm();
    });

    const handleForm = (e) => this.submitForm(e);
    form.addEventListener("submit", handleForm, false);
  }

  static handleModalClose(modalWrapper) {
    modalWrapper.addEventListener("mousedown", (e) => {
      e.stopPropagation();
      const { target, currentTarget } = e;

      const body = currentTarget.closest("BODY");
      const modals = body.querySelectorAll(".form-modal.modal, .callout-modal");

      if (target === currentTarget) {
        //Resets all list inputs:
        const listModals = dqa(".list-modal");

        listModals.forEach((list) => resetList(list));

        modals.forEach((modal) => modal.classList.remove("active"));
        modalWrapper.classList.remove("active");

        const newItemBtn = dqs(".new-item-btn");
        if (newItemBtn) newItemBtn.classList.remove("active");
      }
    });
  }

  mergeInputData(type, fields = [], fieldset = false) {
    let inputs = [];

    const mapData = (type, field) => {
      let inputOptions = [];
      let inputValue, inputSet;
      let key = camelCase(field);

      if (this.options[key]) inputOptions = { options: this.options[key] };
      if (this.values[key] || this.values[key] === false)
        inputValue = { value: this.values[key] };

      if (fieldset) inputSet = { set: fieldset };

      return {
        type: type,
        field: field,
        ...inputOptions,
        ...inputValue,
        ...inputSet,
      };
    };

    if (fields.length) {
      inputs = [...inputs, ...fields.map((field) => mapData(type, field))];
    }
    return inputs;
  }

  setOptions(optionsWrapper, fields) {
    optionsWrapper.innerHTML = "";
    let dropdowns = [];
    for (const type in fields) {
      let inputType = fields[type];
      switch (type) {
        case "checkbox":
        case "dropdown":
          //Merges dropdowns and checkboxes:
          let dropdownFields = this.mergeInputData(type, inputType);
          dropdowns = [...dropdowns, ...dropdownFields];
          break;
        case "listset":
        case "matrix":
          //Creates matrix inputs:
          this.createMatrix(optionsWrapper);
          break;
        case "fieldset":
          this.createFieldset(optionsWrapper, inputType);
          break;
        case "text":
          //Creates text inputs:
          let textFields = this.mergeInputData(type, inputType);
          this.createTextInputs(optionsWrapper, textFields);
          break;
        case "switch":
          let switchFields = this.mergeInputData(type, inputType);
          this.createSwitchInputs(optionsWrapper, switchFields);
          break;
      }
    }

    //Creates dropdown & checkbox inputs:
    if (dropdowns.length) {
      this.createDropdowns(optionsWrapper, dropdowns);
    }

    //Exchange rate forms have a different workflow:
    if (this.key === "currencies") this.insertExchangeRates(optionsWrapper);

    const inputs = optionsWrapper.querySelectorAll(
      "INPUT.capture, INPUT.optional",
    );
    const validateParams = {
      form: optionsWrapper,
      inputs: inputs,
      validate: this.validate,
      conditions: this.conditions,
    };

    const validateInputs = new ValidationController(validateParams);
    validateInputs.handleValidation();
  }

  insertFormOptions() {
    let id = this.fields?.field || this.key;
    id = kababCase(id);

    const form = this.wrapper.querySelector(`#${id}-form`);

    this.setOptions(form, this.fields);
    //Creates submit/cnx buttons:
    this.createButtons(form);
  }

  insertStagedOptions(stage, index, fields) {
    let id = this.fields?.field || this.key;
    id = kababCase(id);

    let active = "";
    if (index === 1) active = "active";

    const form = this.wrapper.querySelector(`#${id}-form`);
    form.insertAdjacentHTML(
      "beforeend",
      `<stage id="${stage}-stage" class="form-stage ${active}" data-stage="${index}"></stage>`,
    );

    const stageWrapper = form.querySelector(`#${stage}-stage`);

    this.setOptions(stageWrapper, fields);
  }

  disableInputs(ids) {
    ids.forEach((id) => {
      const inputs = this.wrapper.querySelectorAll(`INPUT[name="${id}"`);
      inputs.forEach((input) => {
        if (input) {
          input.disabled = true;
          input.classList.remove("capture");
          input.classList.add("immutable");
          const inputWrapper = input.closest(".input-wrapper");
          const immutable = `<div class="input-icon immutable">${pageIcons.lockIcon}</div>`;

          if (!input.classList.contains("matrix-input")) {
            inputWrapper.insertAdjacentHTML("beforeend", immutable);
          }
        } else {
          console.error(`Error: ${id} input could not be found.`);
        }
      });
    });
  }

  handleExchangeRateState(liveCurrency) {
    const form = this.wrapper.querySelector("#exchange-rates-form");
    const legend = form.querySelector("LEGEND");
    const editButton = form.querySelectorAll(`BUTTON[name="edit-button"]`);

    if (liveCurrency.checked) {
      //submitIcons.forEach((wrapper) => wrapper.classList.remove("active"));
      legend.innerHTML = `<span>LIVE EXCHANGE RATES</span>`;
      editButton.forEach((button) => {
        button.innerHTML = pageIcons.lockIcon;
        button.disabled = true;
      });
    } else {
      //submitIcons.forEach((wrapper) => wrapper.classList.add("active"));
      legend.innerHTML = `<span>MANUAL EXCHANGE RATES</span>`;
      editButton.forEach((button) => {
        button.innerHTML = pageIcons.editIcon;
        button.disabled = false;
      });
    }
  }

  insertExchangeRates(form) {
    switch (this.fields.field) {
      case "exchange-rates":
        const addToMatrix = form.querySelector(".add-option-wrapper");
        addToMatrix.remove();
        const selector = `BUTTON[name="delete-button"]`;
        const deleteButtons = form.querySelectorAll(selector);
        deleteButtons.forEach((button) => (button.style.display = "none"));
        const group = form.closest(".flex-group-wrapper");
        const liveCurrency = group.querySelector("INPUT#live-currency");
        this.handleExchangeRateState(liveCurrency);
        break;
    }
  }

  insertPasswordReset() {
    const modal = this.wrapper.querySelector(`#${this.key}-modal`);
    const submitWrapper = modal.querySelector(".submit-wrapper");

    const button = `<button id="reset-password" name="Reset password" class="text-button" type="button">Forgot password?</button>`;

    submitWrapper.insertAdjacentHTML("beforebegin", button);

    const resetPassword = dqs("#reset-password");
    resetPassword.addEventListener("click", (e) => {
      e.preventDefault();
      const { currentTarget } = e;

      const form = currentTarget.closest("FORM");
      const email = form["email"].value.replace("@", "%40") || false;

      const resetUrl = `./reset.html?email=${email}`;
      window.location = resetUrl;

      return false;
    });
  }

  insertOauth() {
    const loginModal = this.wrapper.querySelector("#login-modal");
    loginModal.insertAdjacentHTML(
      "beforeend",
      `<div id="o-auth-wrapper">
        <div class="divider-line"><span class="auth-txt">Or login with</span></div>
        <div id="google-auth" class="auth-button-wrapper">
          <button class="auth-button google btn">${pageIcons.googleLogo} Google</button>
          <div id="google-btn" style="display:none"></div>
        </div>
      </div>`,
    );

    const callback = (response) => this.handleGoogleCredentials(response);

    const IdConfiguration = {
      client_id:
        "1055449310022-f1b9l7k0hr7ckloh0f1j3ucagfdgj9nt.apps.googleusercontent.com",
      callback: callback,
      scope: "openid email profile",
      cancel_on_tap_outside: true,
    };

    const googleButton = loginModal.querySelector("#google-btn");
    try {
      google.accounts.id.initialize(IdConfiguration);
      google.accounts.id.renderButton(googleButton, {
        theme: "outline",
        text: "continue_with",
      });
      google.accounts.id.prompt();
    } catch (err) {
      console.log(err.message);
    }

    const handleGoogleAuth = loginModal.querySelector(".auth-button.google");
    const googleLogin = googleButton.querySelector("div[role=button]");
    handleGoogleAuth.addEventListener("click", (e) => googleLogin.click());
  }

  insertDeleteForm(collection) {
    collection = removePlural(collection);
    const deleteForm = `<div id="delete-item-modal" class="form-modal modal small-modal">
    <div class="modal-inner-wrapper">
      <form id="del-item-form">
        <div class="modal-title-grid">
          <h1>DELETE!</h1>
          <button aria-label="close-modal" class="close-button" tabindex="-1">${pageIcons.cnxIcon}</button>
        </div>
        <h6>Are you sure you want to delete this ${collection.toLowerCase()}:</h6>
        <div id="delete-doc-strip" class="details-strip"></div>
        <div class="submit-buttons submit-wrapper">
          <input type="submit" name="delete" value="Delete" tabindex="0" class="btn active">
          <input type="reset" name="cancel" value="Cancel" tabindex="0" class="btn active">
        </div>
        <p class="error"></p>
      </form>
    </div>
  </div>`;

    this.wrapper.insertAdjacentHTML("beforeend", deleteForm);

    const modal = this.wrapper.querySelector(`#delete-item-modal`);
    const form = dqs(`#del-item-form`);
    const closeButton = modal.querySelector(".close-button");

    closeButton.addEventListener("click", (e) => {
      e.preventDefault();
      modal.classList.remove("active");
      this.wrapper.classList.remove("active");

      const validate = new ValidationController({ form: form });
      validate.resetForm();
    });

    //Cancels the delete & closed the modal:
    const cancelButton = form.querySelector(`INPUT[type="reset"]`);
    cancelButton.addEventListener("click", (e) => {
      form.reset();
      modal.classList.remove("active");
    });

    form.addEventListener("submit", this.deleteDocument);
  }

  async deleteDocument(e) {
    e.preventDefault();
    const { currentTarget } = e;

    const _id = currentTarget.dataset._id;
    const collection = document.body.dataset.collection;

    const docId = { _id: ObjectId(_id) };
    await getDb({ collection: collection });

    let result;
    try {
      if (!_id) throw new Error(`Document _id not found.`);
      result = await deleteDoc(docId);
    } catch (err) {
      console.error("Error deleting product:", err.message);
    }

    if (result?.deletedCount === 1) {
      console.log("Successfully deleted one document.");
      currentTarget.reset();
      currentTarget.closest(".form-modal").classList.remove("active");
    } else {
      console.log("No documents matched the query. Deleted 0 documents.");
    }
  }

  insertPasswordToggle(form) {
    const passwordInputs = form.querySelectorAll("INPUT[type=password]");
    const togglePasswordButton = form.querySelector("#toggle-password");

    const togglePassword = () => {
      passwordInputs.forEach((passwordInput) => {
        if (passwordInput.type === "password") {
          passwordInput.type = "text";
          togglePasswordButton.textContent = "Hide password";
          togglePasswordButton.setAttribute("aria-label", "Hide password.");
        } else {
          passwordInput.type = "password";
          togglePasswordButton.textContent = "Show password";
          togglePasswordButton.setAttribute(
            "aria-label",
            "Show password as plain text. " +
              "Warning: this will display your password on the screen.",
          );
        }
      });
    };

    if (togglePasswordButton)
      togglePasswordButton.addEventListener("click", togglePassword);
  }

  static updateModalMessage({ modal, title, message } = {}) {
    const formTitle = modal.querySelector("h3");
    const formMessage = modal.querySelector("p");

    formTitle.textContent = title;
    formMessage.innerHTML = message;
  }

  static displayFormErrors(form, err) {
    const statusCode = err.statusCode;

    const message = document.createElement("A");
    message.classList.add("error", "error-message");
    form.classList.add("error");

    let errorMessage = sentanceCase(err?.errorCode || err?.message);

    console.log("statusCode", statusCode);
    switch (statusCode) {
      case 404:
        console.log("404 Error!");
        break;
      case 401:
        if (err.message.includes("confirm")) {
          errorMessage = `<b>${errorMessage}:</b> Please check your inbox.`;
        }
        break;
      case 500:
        break;
    }

    message.innerHTML = errorMessage;
    form.insertAdjacentElement("beforeend", message);
  }

  async handleLoginForm(e) {
    const { currentTarget } = e;

    const email = currentTarget["email"].value.toLowerCase();
    const password = currentTarget["current-password"].value;

    let user;
    try {
      user = await login(email, password);
    } catch (err) {
      let message = err.message;
      console.error("Error logining in:", message);
      this.constructor.displayFormErrors(currentTarget, err);
    }

    if (user) {
      this.handleUserLoginWorkflow(user);
    }
  }

  async handleGoogleCredentials(response) {
    let user;
    try {
      user = await AuthUser.authGoogle(response);
    } catch (err) {
      console.log(`Authorisation failed: ${err.message}`);
    }

    if (user) {
      this.handleUserLoginWorkflow(user);
    }
  }

  handleUserLoginWorkflow(user) {
    let url = new URL(window.location);
    let { searchParams } = url;

    const shopify = searchParams.get("code");
    const wsid = user.customData.wsid;

    if (wsid) {
      if (shopify) {
        searchParams = "?" + searchParams.toString();
        window.location = `./confirm.html${searchParams}`;
      } else {
        window.location = "./dashboard.html";
      }
    } else {
      //User has logged in but does not have a workspace:
      window.location = "./create-workspace.html";
    }
  }

  async handleRegisterForm(e) {
    const { currentTarget } = e;

    const email = currentTarget["email"].value.toLowerCase();
    const password = currentTarget["new-password"].value;

    const handleRegistrationSuccess = (currentTarget, email) => {
      const authWrapper = currentTarget.closest("#auth-wrapper");
      const registrationWrapper = dqs("#registration-wrapper");

      authWrapper.style.display = "none";

      const registrationStatus = `<div id="registration-status">
        <div class="brand-wrapper"></div>
          <h1>Check your emails!</h1>
          <div class="icon-wrapper">${pageIcons.mailIcon}</div>
          <div id="registration-text">
            <span>A confirmation email has been sent to <b>${email}</b>. Please click on the confirmation link inside to complete your registration.</span>
          </div>
          <div id="registration-comments">
            <span><b>Don't see an email</b>? Check your spam folder.</span></br>
            <span><b>Link expired?</b></span><button id="resend-confirmation">Resend confirmation email</button>
          </div>
    
        </div>`;

      registrationWrapper.insertAdjacentHTML("beforeend", registrationStatus);

      const resendButton = registrationWrapper.querySelector(
        "#resend-confirmation",
      );

      resendButton.addEventListener("click", (e) => {
        AuthUser.resendConfirmation(email);
      });
    };

    AuthUser.register(email, password)
      .then(() => {
        currentTarget.reset();
        handleRegistrationSuccess(currentTarget, email);
      })
      .catch((err) => {
        let message = err.message;
        console.error("Error registering email:", message);
        this.constructor.displayFormErrors(currentTarget, err);
      });
  }

  async handleCreateWorkspaceForm(e) {
    const { currentTarget, submitter } = e;

    let params = {};
    const inputs = currentTarget.querySelectorAll("INPUT.capture");

    params = Array.from(inputs).map((input) => input.dataset);

    console.log("handleCreateWorkspaceForm/params", params);

    let result;
    try {
      result = await AuthUser.createNewWorkspace(params);
    } catch (err) {
      let message = err.message;

      console.log(message);

      let { cause, field } = message;

      submitter.disabled = false;

      if (cause) {
        const selector = `INPUT[data-key="${field}"]`;
        const input = currentTarget.querySelector(selector);
        let stage = input.closest("STAGE").dataset.stage;
        stage = Number(stage);

        this.setCurrentStage(submitter.parentElement, stage);

        const event = new CustomEvent("mongoError", {
          bubbles: true,
          detail: { type: "error", value: cause },
        });
        console.log({ input: input, event: event });
        //Send key with error message to id field with error!
        input.dispatchEvent(event);
      } else {
        console.log("Error creating workspace:", message);
        this.constructor.displayFormErrors(currentTarget, err);
      }
    }

    console.log("handleCreateWorkspaceForm/result", result);

    if (result) {
      currentTarget.reset();
      currentTarget.style.display = "none";

      const modal = currentTarget.closest(".form-modal");

      //Displays an instructional message:
      const messageOptions = {
        modal: modal,
        title: `WORKSPACE CREATED`,
        message: `Start making products!`,
      };

      this.constructor.updateModalMessage(messageOptions);
    }
  }

  async handleJoinWorkspaceForm(e) {
    const { currentTarget } = e;

    //Detects if the user clicked accept:
    const submitter = e.submitter;
    const handleValidity = submitter.name;

    const workspaceDoc = {
      firstName: firstName.value,
      lastName: lastName.value,
      acceptedInvite: handleValidity,
    };

    AuthUser.joinWorkspace(workspaceDoc)
      .then(() => {
        const modal = currentTarget.closest(".form-modal");

        //Displays an instructional message:
        const messageOptions = {
          modal: modal,
          title: `SUCCESS!`,
          message: `You have joined the <a class="workspace-name"></a> workspace!`,
        };

        this.constructor.updateModalMessage(messageOptions);
      })
      .catch((err) => {
        const error = EJSON.parse(err.error);

        let message = error.message;
        console.log("Error joining workspace:", message);
        this.constructor.displayFormErrors(joinWorkSpaceForm, error);
      });
  }

  async handleRequestPasswordResetForm(e) {
    const { currentTarget } = e;
    const email = currentTarget["email"].value;
    const modal = currentTarget.closest(".form-modal");

    AuthUser.requestPasswordReset(email)
      .then(() => {
        //Hides the form:
        currentTarget.style.display = "none";

        //Displays an instructional message:
        const messageOptions = {
          modal: modal,
          title: `Check your emails!`,
          message: `An email has be sent to you with instructions about how to reset your password.`,
        };

        this.constructor.updateModalMessage(messageOptions);
      })
      .catch((err) => {
        const error = EJSON.parse(err.error);

        let message = error.message;
        console.log("Error requesting password:", message);
        this.constructor.displayFormErrors(currentTarget, error);
      });
  }

  async handleRequestConfirmationForm(e) {
    const { currentTarget } = e;
    const email = currentTarget["confirm-email"].value;

    try {
      await AuthUser.resendConfirmation(email);
    } catch (err) {
      console.log(err);
      const confirmError = new WorkspaceErrors(err);
      confirmError.handleErrors();
    }
  }

  async handlePasswordResetForm(e) {
    const { currentTarget } = e;

    const password = currentTarget["new-password"].value;
    const modal = currentTarget.closest(".form-modal");

    //Grab Tokens
    const queryParams = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const MongoToken = queryParams.token;
    const tokenId = queryParams.tokenId;

    AuthUser.resetPassword(MongoToken, tokenId, password)
      .then(() => {
        currentTarget.style.display = "none";

        //Displays an instructional message:
        const messageOptions = {
          modal: modal,
          title: `Password reset complete`,
          message: `Your password has been successfully reset.`,
        };

        this.constructor.updateModalMessage(messageOptions);

        setTimeout(() => {
          window.location = "./index.html";
        }, "1000");
      })
      .catch((err) => {
        const error = EJSON.parse(err.error);

        let message = error.message;
        console.log("Error resetting password:", message);
        this.constructor.displayFormErrors(currentTarget, error);
      });
  }

  updateLocalValues({ _id, ...updatedVales } = {}) {
    let updated_id = _id;
    this.values = this.values.map(({ _id, ...originalValues } = {}) => {
      if (_id.toString() === updated_id.toString()) {
        for (const key in originalValues) {
          if (updatedVales[key]) {
            originalValues[key] = updatedVales[key];
          }
        }
        return { _id: _id, ...originalValues };
      } else {
        return { _id: _id, ...originalValues };
      }
    });
  }

  processDataset({ dataset } = {}) {
    //Indicates a nested value:
    const nestedValues = ["_id", "code"];

    let { value, key, ...rest } = dataset;

    let processed = { key: key, value: value };

    for (const key in rest) {
      const isNestedVal = nestedValues.includes(key);
      const isNestedObj = key === "field";

      if (isNestedVal) {
        processed.value = { value: value, [key]: rest[key] };
      } else if (isNestedObj) {
        processed[key] = rest[key];
      }
    }

    return processed;
  }

  async handleNewItemForm(e) {
    const { currentTarget, submitter } = e;
    const inputs = currentTarget.querySelectorAll(".capture");

    const datasets = [...inputs].map((input) => this.processDataset(input));

    submitter.disabled = true;

    const collection = document.body.dataset.collection;

    const validate = new ValidationController({ form: currentTarget });

    let params = {
      key: collection,
      data: datasets,
    };

    console.log("handleNewItemForm/params", params);

    let result = {};
    try {
      result = await insertItem(params);
    } catch (err) {
      let message = err.message;

      console.log(message);

      let { cause, field } = message;

      submitter.disabled = false;

      if (cause) {
        cause = sentanceCase(cause?.trim());

        const event = new CustomEvent("mongoError", {
          bubbles: true,
          detail: { type: "error", value: cause },
        });

        const selector = `INPUT[data-key="${field}"]`;
        const input = currentTarget.querySelector(selector);
        console.log({ input: input, event: event });
        //Send key with error message to id field with error!
        input.dispatchEvent(event);
      } else {
        console.log(`Error saving new ${collection}:`, message);

        this.constructor.displayFormErrors(currentTarget, err);
      }
    }

    if (result?.insertedId) {
      console.log(`Item inserted with the _id: ${result.insertedId}`);
      validate.resetForm();
    }
  }

  async handleUserSettingsForm(e) {
    const { currentTarget, submitter } = e;

    submitter.disabled = true;

    let _id, userId, operation;

    const selectors = ".add-option-wrapper, .matrix-item";
    const inputWrapper = submitter.closest(selectors);

    const inputs = inputWrapper.querySelectorAll("INPUT.capture.is-valid");
    let datasets = [...inputs].map((input) => this.processDataset(input));

    //Assigns the userId to the dataset:
    _id = inputWrapper?.dataset._id;
    if (_id) userId = [{ key: "_id", value: ObjectId(_id) }];
    datasets = [...datasets, ...(userId || [])];

    operation = submitter.value;

    const params = {
      data: datasets,
      operation: operation,
    };

    console.log("handleUserSettingsForm/params", params);

    let result;
    try {
      result = await updateUserSettings(params);
    } catch (err) {
      let message = err.message;

      console.log(message);

      let { cause, field } = message;

      submitter.disabled = false;

      if (cause) {
        cause = sentanceCase(cause?.trim());

        const event = new CustomEvent("mongoError", {
          bubbles: true,
          detail: { type: "error", value: cause },
        });

        const selector = `INPUT[data-key="${field}"]`;
        const input = inputWrapper.querySelector(selector);
        console.log({ input: input, event: event });
        //Send key with error message to id field with error!
        input.dispatchEvent(event);
      }
    }

    if (result?.success) {
      switch (operation) {
        case "add":
          const matrix = currentTarget.querySelector("MATRIX");

          this.values = [...this.values, result];

          this.createMatrixRows(matrix, [result]);
          this.addMatrixValidation(currentTarget, result);
          this.disableMatrixInputs([inputWrapper], true);
          break;
        case "update":
          this.updateLocalValues(result);
          this.constructor.updateMatrix(currentTarget, result);
          this.disableMatrixInputs([inputWrapper], false);
          break;
        case "delete":
          inputWrapper.remove();

          let deleted_id = result?._id?.toString();
          this.values = this.values.filter(
            ({ _id } = {}) => _id.toString() !== deleted_id,
          );

          this.disableMatrixInputs([inputWrapper], false);
          break;
      }
    }
  }

  async handleTypeSettingsForm(e) {
    const { currentTarget, submitter } = e;

    submitter.disabled = true;

    let key,
      field,
      data = {},
      operation;

    key = camelCase(this.key);
    field = camelCase(this.fields.field);

    const inputWrapper = submitter.closest(".add-option-wrapper, .matrix-item");

    const input = inputWrapper.querySelector("INPUT.capture");

    let _id, value;

    _id = inputWrapper?.dataset._id;
    value = input?.dataset.value;

    console.log(value);
    if (_id) data._id = ObjectId(_id);
    if (value) data.value = value;

    operation = submitter.value;

    const params = {
      key: key,
      data: [{ ...data, key: field }],
      operation: operation,
    };

    console.log("handleTypeSettings/params", params);

    let result;
    try {
      result = await updateTypeSettings(params);
    } catch (err) {
      let message = err.message;

      console.log(message);

      message = sentanceCase(message.trim());

      const event = new CustomEvent("mongoError", {
        bubbles: true,
        detail: { type: "error", value: message },
      });

      console.log({ input: input, event: event });
      input.dispatchEvent(event);
    }

    if (result?.success) {
      let data = {};
      switch (operation) {
        case "add":
          const matrix = currentTarget.querySelector("MATRIX");

          let new_id, newValue;
          new_id = result?._id;

          newValue = { _id: ObjectId(new_id), value: value };
          data = { _id: new_id, value: value };

          this.values = [...this.values, newValue];

          this.createMatrixRows(matrix, [data]);
          this.addMatrixValidation(currentTarget, data);
          this.disableMatrixInputs([inputWrapper], true);
          break;
        case "update":
          let updated_id = result?._id;

          data = { _id: updated_id, value: value };

          this.updateLocalValues(data);
          this.constructor.updateMatrix(currentTarget, data);
          this.disableMatrixInputs([inputWrapper], false);
          break;
        case "delete":
          inputWrapper.remove();

          let deleted_id = result?._id?.toString();
          this.values = this.values.filter(
            ({ _id } = {}) => _id.toString() !== deleted_id,
          );

          this.disableMatrixInputs([inputWrapper], false);
          break;
      }
    }
  }

  async handleVariantSettingsForm(e) {
    const { currentTarget, submitter } = e;

    submitter.disabled = true;

    let _id, sizeId, key, field, operation;

    key = camelCase(this.key);
    field = camelCase(this.fields.field);
    operation = submitter.value;

    const handleSizeSettings = async () => {
      const selectors = ".add-option-wrapper, .matrix-item";
      const inputWrapper = submitter.closest(selectors);

      const inputs = inputWrapper.querySelectorAll("INPUT.capture.is-valid");

      let datasets = [];
      let sizeBridge = {};
      if (inputs.length) {
        sizeBridge = { key: "", value: [] };
        for (const { name, dataset, defaultValue } of inputs) {
          if (name === "size") {
            //Creates an array of all sizeBridge input datasets:
            sizeBridge.key = "sizeBridge";

            switch (operation) {
              case "add":
                sizeBridge.value.push(dataset);
                break;
              case "update":
                if (defaultValue) {
                  if (dataset.value) {
                    //Update size value:
                    dataset.operator = "set";
                  } else {
                    //Delete size value:
                    dataset.operator = "pull";
                  }
                  sizeBridge.value.push(dataset);
                } else {
                  dataset.operator = "addToSet";
                  sizeBridge.value.push(dataset);
                }
                break;
            }
          } else if (dataset) {
            //Creates an array of all default input datasets:
            datasets = [...datasets, dataset];
          }
        }
      }

      if (sizeBridge?.value?.length) datasets.push(sizeBridge);

      //Assigns the new variantId to the dataset:
      _id = inputWrapper?.dataset._id;
      if (_id) sizeId = [{ key: "_id", value: ObjectId(_id) }];
      datasets = [...datasets, ...(sizeId || [])];

      const params = {
        key: key,
        field: field,
        data: datasets,
        operation: operation,
      };

      console.log("params", params);

      let result;
      try {
        if (!datasets.length) throw new Error("Error saving size, no data.");

        result = await updateSizeSettings(params);
      } catch (err) {
        let message = err.message;

        console.log(message);

        let { cause, field } = message;

        submitter.disabled = false;

        if (cause) {
          cause = sentanceCase(cause?.trim());

          const event = new CustomEvent("mongoError", {
            bubbles: true,
            detail: { type: "error", value: cause },
          });

          const selector = `INPUT[data-key="${field}"]`;
          const input = inputWrapper.querySelector(selector);
          console.log({ input: input, event: event });
          //Send key with error message to id field with error!
          input.dispatchEvent(event);
        }
      }

      if (result?.success) {
        console.log({ result: result });
        switch (operation) {
          case "add":
            const matrix = currentTarget.querySelector("MATRIX");

            this.values = [...this.values, result];

            this.createMatrixRows(matrix, [result]);
            this.addMatrixValidation(currentTarget, result);
            this.disableMatrixInputs([inputWrapper], true);
            break;
          case "update":
            this.updateLocalValues(result);

            this.constructor.updateMatrix(currentTarget, result);
            this.disableMatrixInputs([inputWrapper], false);
            break;
          case "delete":
            inputWrapper.remove();

            let deleted_id = result?._id?.toString();
            this.values = this.values.filter(
              ({ _id } = {}) => _id.toString() !== deleted_id,
            );

            this.disableMatrixInputs([inputWrapper], false);
            break;
        }
      }
    };

    switch (field) {
      case "colors":
        this.handleTypeSettingsForm(e);
        break;
      case "sizes":
        handleSizeSettings();
        break;
    }
  }

  async handleWorkspaceSettingsForm(e) {
    const { currentTarget, submitter } = e;

    submitter.disabled = true;

    const inputs = currentTarget.querySelectorAll("INPUT.capture.is-valid");

    let datasets = [...inputs].map((input) => this.processDataset(input));

    const params = {
      key: this.key,
      data: datasets,
    };

    console.log("handleWorkspaceSettings/params", params);

    let result;
    try {
      result = await updateWorkSpaceSettings(params);
    } catch (err) {
      let message = err.message;

      console.log(err.error);

      let { cause, field } = message;

      submitter.disabled = false;

      if (cause) {
        cause = sentanceCase(cause?.trim());

        const event = new CustomEvent("mongoError", {
          bubbles: true,
          detail: { type: "error", value: cause },
        });

        const selector = `INPUT[data-key="${field}"]`;
        const input = inputWrapper.querySelector(selector);
        console.log({ input: input, event: event });
        //Send key with error message to id field with error!
        input.dispatchEvent(event);
      } else {
        this.constructor.displayFormErrors(currentTarget, err);
      }
    }

    const validate = new ValidationController({ form: currentTarget });
    if (result?.success) {
      const update = result.update;
      validate.resetForm(update);
    } else if (!result?.success) {
      validate.resetForm();
    }
  }

  async handleCurrencySettingsForm(e) {
    const { currentTarget, submitter } = e;

    let field;

    field = this.fields.field;

    const inputs = currentTarget.querySelectorAll("INPUT.capture.is-valid");
    let datasets = Array.from(inputs).map((input) => input.dataset);

    let params = {
      field: camelCase(field),
    };

    switch (field) {
      case "home-currency":
        break;
      case "live-currency":
        const homeCurrency = this.wrapper.querySelector(
          `INPUT[name="home-currency"]`,
        );

        datasets = [currentTarget.dataset, homeCurrency.dataset];
        params.data = datasets;

        break;
      case "exchange-rates":
        params.data = datasets;
        break;
    }

    console.log("handleCurrencySettingsForm/params", params);

    let result;
    try {
      result = await updateCurrencySettings(params);
    } catch (err) {
      let message = err.message;

      console.log("Error", err);

      let { cause, field } = message;

      switch (this.fields.field) {
        case "home-currency":
          break;
        case "live-currency":
          if (currentTarget.checked) {
            currentTarget.checked = false;
          } else {
            currentTarget.checked = true;
          }
          break;
        case "exchange-rates":
          break;
      }

      if (cause) {
        cause = sentanceCase(cause?.trim());

        const event = new CustomEvent("mongoError", {
          bubbles: true,
          detail: { type: "error", value: cause },
        });

        const selector = `INPUT[data-key="${field}"]`;
        const input = inputWrapper.querySelector(selector);
        console.log({ input: input, event: event });
        //Send key with error message to id field with error!
        input.dispatchEvent(event);
      } else {
        this.constructor.displayFormErrors(currentTarget, err);
      }
    }

    if (result?.success) {
      switch (field) {
        case "home-currency":
          break;
        case "live-currency":
          this.handleExchangeRateState(currentTarget);
          this.values = { liveCurrency: currentTarget.checked };

          const currencyTab = dqs("#currencies-button");

          if (currentTarget.checked) {
            currencyTab.click();
          }

          break;
        case "exchange-rates":
          console.log(this.values);
          this.updateLocalValues(result);
          this.constructor.updateMatrix(currentTarget, result);
          const selectors = ".add-option-wrapper, .matrix-item";
          const inputWrapper = submitter.closest(selectors);
          this.disableMatrixInputs([inputWrapper], false);
          break;
      }
    }
  }

  async submitForm(e) {
    e.preventDefault();

    console.log("submitForm", this.key);

    switch (this.key) {
      case "login":
        await this.handleLoginForm(e);
        break;
      case "register":
        await this.handleRegisterForm(e);
        break;
      case "create-workspace":
        await this.handleCreateWorkspaceForm(e);
        break;
      case "join-workspace":
        await this.handleJoinWorkspaceForm(e);
        break;
      case "request-password-reset":
        await this.handleRequestPasswordResetForm(e);
        break;
      case "request-confirmation":
        await this.handleRequestConfirmationForm(e);
        break;
      case "reset-password":
        this.handlePasswordResetForm(e);
        break;
      case "new-item":
        await this.handleNewItemForm(e);
        break;
      case "users":
        await this.handleUserSettingsForm(e);
        break;
      //Type settings:
      case "views":
      case "types":
        await this.handleTypeSettingsForm(e);
        break;
      case "currencies":
        await this.handleCurrencySettingsForm(e);
        break;
      case "variants":
        await this.handleVariantSettingsForm(e);
        break;
      //Workspace settings:
      case "workspace":
      case "profile":
        await this.handleWorkspaceSettingsForm(e);
        break;
    }
  }
}

export class TableEditor extends FormBuilder {
  constructor({
    wrapper,
    key,
    collection,
    fields,
    activeFields,
    options = {},
    values = [],
    conditions,
    validate,
  } = {}) {
    super();
    this.row = wrapper;
    this.key = key;
    this.collection = collection;
    this.fields = fields;
    this.activeFields = activeFields;
    this.options = options;
    this.values = values;
    this.conditions = conditions;
    this.validate = validate;
  }

  insertRowOptions() {
    const system = ["sku", "supplier-id"];
    const active = (f) => this.activeFields.includes(camelCase(f));

    let {
      checkbox = [],
      dropdown = [],
      fieldset = [],
      text = [],
    } = this.fields;

    //Merges fieldset inputs:
    fieldset = fieldset.reduce((acc, { key, ...fs }) => {
      for (const field in fs) {
        acc[field] = fs[field];
      }

      return acc;
    }, {});

    //Creates dropdown & checkbox inputs:
    dropdown = [...dropdown, ...(fieldset?.dropdown || [])];
    dropdown = dropdown.filter(active);
    let dropdownFields = this.mergeInputData("dropdown", dropdown);

    checkbox = [...checkbox, ...(fieldset?.checkbox || [])];
    checkbox = checkbox.filter(active);
    dropdownFields = [
      ...dropdownFields,
      ...this.mergeInputData("checkbox", checkbox),
    ];

    this.createDropdowns(this.row, dropdownFields, false);

    //Creates text inputs:
    text = [...text, ...(fieldset?.text || [])];
    text = text.filter(active);
    text = text.filter((t) => !system.includes(t));
    let textFields = this.mergeInputData("text", text);
    this.createTextInputs(this.row, textFields, false);
  }
}
