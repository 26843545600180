import { tableSettings } from "./local.mjs";
import { EditCases } from "./format.mjs";
const { currenyFields } = tableSettings;
const { camelCase } = EditCases;

export default class CurrencyController {
  static convert(exchangeRate, value) {
    const roundDown = (cost) => {
      let rounded = Math.round((cost + Number.EPSILON) * 100) / 100;
      return rounded;
    };

    if (exchangeRate > 1) {
      return roundDown(value / exchangeRate);
    } else {
      return roundDown(value * exchangeRate);
    }
  }

  static formatCurrency(homeCurrency, value, itemCurrency) {
    const currencies = {
      EUR: "de-DE",
      GBP: "en-GB",
      USD: "en-US",
      JPY: "ja-JP",
    };

    let formatted;
    const number = Number(value);

    const format = (number) => {
      return new Intl.NumberFormat(currencies[homeCurrency], {
        style: "currency",
        currency: itemCurrency || homeCurrency,
      }).format(number);
    };

    formatted = format(number).replace("US", "");

    return formatted;
  }

  static setCurrency(homeCurrency, item) {
    const removeSymbols = (value) => {
      let string = value
        ?.toString()
        ?.replace(/[^a-zA-Z0-9.,]/g, "")
        ?.trim();
      let number = Number(string);

      if (isNaN(number)) {
        return string;
      } else {
        return number;
      }
    };

    let currency = removeSymbols(item?.currency);

    for (const fieldTypes in currenyFields) {
      const isHome = fieldTypes.includes("home");
      const convertBy = isHome ? homeCurrency : currency;

      for (const field of currenyFields[fieldTypes]) {
        const key = camelCase(field);
        const value = item[key];

        if (value !== undefined) {
          item[key] = CurrencyController.formatCurrency(
            homeCurrency,
            removeSymbols(value),
            convertBy,
          );
        }
      }
    }
    return item;
  }

  // array - an array of products or components to coverts costs into home currency.
  // exchangeRates - an object containing all the exchangeRages.
  // convertFields -  an array of keys/fields where the currency is to be converted.
  static convertCurrency(item, exchangeRates, convertFields) {
    const itemCurrency = item?.currency?.split(" ")[0];

    for (const field of convertFields) {
      if (item[field]) {
        let convertCost = item[field];
        const convertRate = exchangeRates.find(
          ({ _id } = {}) => _id === itemCurrency,
        ).value;

        //removes any special charachters:
        if (isNaN(item[field])) {
          convertCost = Number(convertCost.replace(/[^0-9\.-]+/g, ""));
        }

        item[field] = CurrencyController.convert(convertRate, convertCost);
      }
    }

    return item;
  }
}
